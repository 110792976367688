import { addDoc, collection, doc } from 'firebase/firestore';
import React, { useContext, useEffect, useState } from 'react'
import { db } from '../Users_Chats/Firebase';
import { onSnapshot, where, query, orderBy } from 'firebase/firestore';

const UserChat = () => {

    const [message, setMessage] = useState('');
    const [allChat, setAllChat] = useState([]);
    const [data, setData] = useState([]);
    const [userName, setUserName] = useState();
    const [receiverId, setReceiverId] = useState();
    const [senderId, setSenderId] = useState();
    const [senderName, setSenderName] = useState();

    useEffect(() => {
        const Userdata = localStorage.getItem('firebase');
        if (Userdata) {
            const Data = JSON.parse(Userdata);
            setSenderId(Data.senderId);
            setReceiverId(Data.reciverId);
            setSenderName(Data.senderName);
            UserGetHandler(Data.senderId);
        }
    }, [])

    useEffect(() => {
        ChatGetHandler();
    }, [receiverId])

    // Handle sending message
    const handleSendMessage = () => {
        if (senderId && receiverId) {
            const userRef = doc(db, "chats_rooms", senderId + "_" + receiverId);

            const messagesCollectionRef = collection(userRef, "messages");
            addDoc(messagesCollectionRef, {
                message: message,
                reciverId: receiverId,
                senderName: senderName,
                senderid: senderId,
                timestamp: new Date()
            })
                .then((docRef) => {
                    console.log("Message added with ID:", docRef.id);
                    setMessage('');
                })
                .catch((error) => {
                    console.error("Error adding message:", error);
                });
        }
    };

    // User Get Handler
    const UserGetHandler = (uid) => {
        const usersCollection = collection(db, "users");

        const unsubscribe = onSnapshot(usersCollection, (snapshot) => {
            const users = [];
            snapshot.forEach((doc) => {
                if (doc.data().uid != uid) {
                    users.push(doc.data());
                }
            });
            setData(users);
            console.log(users);
        });

        return () => unsubscribe();
    }

    // User Message Get Handler
    const ChatGetHandler = () => {
        if (senderId && receiverId) {
            // // Reference to the users subcollection within a chat room document
            // const chatsRef = collection(db, "chats_rooms");
            // const chatRoomRef = doc(chatsRef, `${senderId}_${receiverId}`); // Chat room document
            // const usersRef = collection(chatRoomRef, "users"); // Users subcollection inside chat room

            // const senderRef = doc(usersRef, senderId); // Sender's document inside the users subcollection
            // const receiverRef = doc(usersRef, receiverId); // Receiver's document inside the users subcollection

            // const messagesRef = collection(senderRef, "messages"); // Messages subcollection for sender
            // const messagesQuery = query(
            //     messagesRef,
            //     where("senderid", "in", [senderId, receiverId]),
            //     orderBy("timestamp", "asc")
            // );

            // // Subscribe to the messages and update state on snapshot
            // const unsubscribe = onSnapshot(messagesQuery, (snapshot) => {
            //     const messages = snapshot.docs.map((doc) => doc.data());
            //     console.log("Messages: ", messages);

            //     // Update state with fetched messages
            //     setAllChat(messages);
            // });

            // return unsubscribe;
        }

        console.warn('senderId or receiverId is missing.');
    };


    // Input messege Handler
    const handleMessageChange = (e) => {
        setMessage(e.target.value);
    };

    //  User Chang Handler
    const UserChangHandler = (user) => {
        setReceiverId(user.uid)
        setUserName(user.name);
    }

    return (
        <div className="App content-body flex justify-center">
            <div className="mt-[20px]">
                {/* <div className="chat-box-header">
                    <h6 className='m-0'>Test</h6>
                </div> */}

                <div className="flex gap-[20px] items-start">
                    <div className="">
                        {
                            data.length > 0 &&
                            data.map((el) => {
                                return <div onClick={() => UserChangHandler(el)} key={el.uid} className="flex items-center cursor-pointer">
                                    <div className="relative">
                                        <img src="https://img.freepik.com/free-vector/blue-circle-with-white-user_78370-4707.jpg" className='w-[70px]' alt="" />
                                        {el.isOnline && (
                                            <div className="absolute bottom-[10px] right-[10px] w-[15px] h-[15px] bg-green-500 rounded-full border-2 border-white"></div>
                                        )}
                                    </div>
                                    <div className='text-start'>
                                        <h6 className='m-0'>{el.name}</h6>
                                        <span>{el.isOnline ? "Online" : "Offline"}</span>
                                    </div>
                                </div>

                            })
                        }
                    </div>
                    <div className="chat-box">
                        <div className="chat-box-header">
                            {receiverId && <h6 className='m-0'>{userName}</h6>}
                        </div>
                        <div className="chat-box-body">
                            {allChat.map((message, index) => (
                                <div
                                    key={index}
                                    className={`message ${message.senderid === senderId ? 'user-message' : 'bot-message'}`}
                                >
                                    {message.message}
                                </div>
                            ))}
                        </div>
                        {receiverId && <div className="chat-box-footer">
                            <input
                                type="text"
                                value={message}
                                onChange={handleMessageChange}
                                placeholder="Type a message..."
                            />
                            <button onClick={handleSendMessage}>Send</button>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserChat