import React, { useContext, useEffect } from 'react';
import { MyContext } from '../Context/MyProvider ';
import { useNavigate } from 'react-router-dom';

const FlutterWave = ({ Amount }) => {
    const { setPayClose, setPlanId, setTransactionId, page, setBuyCoin } = useContext(MyContext);
    const navigate = useNavigate();

    const handlePayment = () => {
        const localData = localStorage.getItem("Register_User");
        if (!localData) return;
        const parsedData = JSON.parse(localData);

        const paymentOptions = {
            tx_ref: Date.now(),
            amount: Amount,
            currency: 'USD',
            email: parsedData.email,
            phone_number: parsedData.mobile,
            public_key: "FLWPUBK_TEST-5760e3ff9888aa1ab5e5cd1ec3f99cb1-X",
            callback: (data) => {
                console.log('Payment response', data);
                if (data.status === 'successful') {
                    console.log('Payment successful');
                    if (page === "Upgrade") {
                        setPlanId("PaymentDone");
                        setTransactionId(data.transaction_id);
                    } else if (page === "Wallet") {
                        setPayClose("PaymentDone");
                    } else {
                        setBuyCoin("PaymentDone");
                    }
                    navigate(0);
                    sessionStorage.setItem("Payment" , "Wallet Update successfully!");
                } else {
                    if (page === "Upgrade") {
                        setPlanId("PaymentNotDone");
                    } else if (page === "Wallet") {
                        setPayClose("PaymentNotDone");
                    } else {
                        setBuyCoin("PaymentNotDone");
                    }
                }
            },
            onClose: () => {
                alert('Payment modal closed');
            },
            customer: {
                email: parsedData.email,
                phone_number: parsedData.mobile,
            },
        };

        window.FlutterwaveCheckout(paymentOptions);
    };

    useEffect(() => {
        if (Amount) {
            handlePayment();
        }
    }, [Amount]);

    return null;
};

export default FlutterWave;
