import React, { useEffect } from 'react'
import "../css/bootstrap.min.css"
import "../css/style.css"
import "../css/responsive.css"
import LoveLinkLogo from "../images/logos/1714030094.png"
import welcome from "../images/welcome/welcome.png"
import { Link } from 'react-router-dom'
const Home = () => {
    
  return (
    <div>
         {/* <!-- Login Area Start --> */}
         <section class="login-wrapper">
            <div class="container">
                <div class="row align-item-center">
                    <div class="col-md-6">
                        <div class="login-content">
                            {/* <!-- Site Logo --> */}
                            <div class="site-logo max-_430_:flex max-_430_:justify-center">
                                <a href="#" class="logo-white d-lg-flex max-width-content justify-end">
                                    <img class="w-[50%] max-_430_:w-[100%]" src={LoveLinkLogo} alt="logo" />
                                </a>
                            </div>
                            {/* <!-- heading --> */}
                            <div class="heading text-white">
                                <h1 class="pt-lg-5 pt-3">Find the Match of &nbsp;<span class="text-orange d-xxl-block">your Life</span></h1>
                                <h4 class="pt-md-3">You deserve to find who you are looking for. Meet them today!</h4>
                            </div>
                             {/* <!-- Login Buttons --> */}
                            <div class="social-btn-list gap-4 py-4">
                                <a href="#" class="btn-social btn-gl">
                                    Let's dive in into your account!
                                </a>
                                <Link to='/registe' class="bg-[rgba(152,14,255,255)] max-_430_:w-[250px] TITLE py-[0.7rem] px-[1.1rem] rounded-[0.6rem] text-white no-underline btn-social">
                                    Continue with Email/moblie Number
                                </Link>
                                <Link to='/login' class=" btn-social btn-fb">
                                   I have an account? <span className='font-[500] ms-[5px]'>Login</span>
                                </Link >
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <img class="img-fluid" src={welcome} alt="img"/>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- Login Area End --> */}
    </div>
  )
}

export default Home