// firebaseConfig.js

import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

// Your Firebase config - Get these from your Firebase Console
const firebaseConfig = {
  apiKey: "AIzaSyBZriAJPETYOV-bYF4XLasPRqyxoFHrGfM",
  authDomain: "gomeet-b0855.firebaseapp.com",
  databaseURL: "https://gomeet-b0855-default-rtdb.firebaseio.com",
  projectId: "gomeet-b0855",
  storageBucket: "gomeet-b0855.firebasestorage.app",
  messagingSenderId: "515256528482",
  appId: "1:515256528482:web:812a734a0471b65189ab3b",
  measurementId: "G-WSSVDSCTKB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore and Firebase Auth
const db = getFirestore(app);
const auth = getAuth(app);

export { db, auth };
