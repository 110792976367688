import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      home: 'Home',
      about: 'About Us',
      contact: 'Contact Us',
      details: 'Details Page',
      lorem: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestias iusto harum tenetur magni laboriosam, minima fuga, sapiente culpa soluta totam non porro. Suscipit distinctio fugit veniam natus laborum ut aut?',
      "Log Out": "Log Out",
      "Account & Security": "Account & Security",
      "Buy Coin": "Buy Coin",
      "Explore": "Explore",
      "Settings": "Settings",
      "Wallet": "Wallet",
      "Edit": "Edit",
      "You're Activated Membersip": "You're Activated Membersip",
      "Active": "Active",
      "Enjoy premium and match anyway": "Enjoy premium and match anyway",
      "Is Verify": "Is Verify",
      "Panding": "Panding",
      "Upload Photo": "Upload Photo",
      "persons Online": "persons Online",
      "There are": "There are",
      "Loading...": "Loading...",
      "Filter": "Filter",
      "Reset": "Reset",
      "Premium": "Premium",
      "Upgrade Now": "Upgrade Now",
      "Apply": "Apply",
      "Add": "Add",
      "Add Your Favorite Photo": "Add Your Favorite Photo",
      "Upload": "Upload",
      "shortly": "shortly",
      "selfies and will get back to you": "selfies and will get back to you",
      "We are currently reviewing your": "We are currently reviewing your",
      "Review": "Review",
      "Verification Under": "Verification Under",
      "No Any Passed Profiles...": "No Any Passed Profiles...",
      "No Add Any Favourite Profiles...": "No Add Any Favourite Profiles...",
      "No Any User Like You...": "No Any User Like You...",
      "No Any Match Profiles...": "No Any Match Profiles...",
      "Passed": "Passed",
      "Favourite": "Favourite",
      "Like Me": "Like Me",
      "New Match": "New Match",
      "Filter & Show": "Filter & Show",
      "Verify Profile": "Verify Profile",
      "Distance Range": "Distance Range",
      "Age": "Age",
      "Serach Preference": "Serach Preference",
      "Interests": "Interests",
      "Langusges I Know": "Langusges I Know",
      "Religion": "Religion",
      "Relationship Goals": "Relationship Goals",
      "Verify Profile": "Verify Profile",
      "Update": "Update",
      "Distance Preference": "Distance Preference",
      "Birthdate": "Birthdate",
      "Password": "Password",
      "Mobile Number": "Mobile Number",
      "Email": "Email",
      "Nick Name": "Nick Name",
      "Update your personal photos here.": "Update your personal photos here.",
      "Height": "Height",
      "CM": "CM",
      "Bio": "Bio",
      "Gender": "Gender",
      "No any Transaction...": "No any Transaction...",
      "Transaction": "Transaction",
      "Top-up": "Top-up",
      "Total Balance": "Total Balance",
      "Coin Buying & Info": "Coin Buying & Info",
      "Withdraw": "Withdraw",
      "Select Coin Package": "Select Coin Package",
      "History": "History",
      "Your Coin": "Your Coin",
      "Coins cannot be transferred to any users...": "Coins cannot be transferred to any users...",
      "Coins can be withdrawn eith the described method only...": "Coins can be withdrawn eith the described method only...",
      "Coins are credited to your Coin balance only...": "Coins are credited to your Coin balance only...",
      "Coins can be used with all payment modes...": "Coins can be used with all payment modes...",
      "Coins don't have any expiry date...": "Coins don't have any expiry date...",
      "Coin can be used for sending gifts only...": "Coin can be used for sending gifts only...",
      "You need a minimum of 1000 coins to make a withdeawal...": "You need a minimum of 1000 coins to make a withdeawal...",
      "The pepole you blocked are displayed here..": "The pepole you blocked are displayed here..",
      "Blocked Users": "Blocked Users",
      "Get Started": "Get Started",
      "Plans": "Plans",
      "Number Of Coin": "Number Of Coin",
      "Paypal": "Paypal",
      "BANK Transfer": "BANK Transfer",
      "Proceed": "Proceed",
      "Cancel": "Cancel",
      "Please Email ID": "Please Email ID",
      "Email ID": "Email ID",
      "Please Enter IFSC Code": "Please Enter IFSC Code",
      "IFSC Code": "IFSC Code",
      "Please Enter Account Holder Name": "Please Enter Account Holder Name",
      "Account Holder Name": "Account Holder Name",
      "Please Enter Bank Name": "Please Enter Bank Name",
      "Bank Name": "Bank Name",
      "Please Enter Account Number": "Please Enter Account Number",
      "Account Number": "Account Number",
      "Please Enter UPI": "Please Enter UPI",
      "UPI": "UPI",
      "Select Type": "Select Type",
      "Please Enter Amount": "Please Enter Amount",
      "Coin History": "Coin History",
      "Withdraw History": "Withdraw History",
      "Yes, Block": "Yes, Block",
      "Cancel": "Cancel",
      "You can unblock them anytime in Settings.": "You can unblock them anytime in Settings.",
      "They will not be notified if you block them.": "They will not be notified if you block them.",
      "They will not be able to find your profile and send you messages.": "They will not be able to find your profile and send you messages.",
      "Acchcuu. Don't worry we won't tell them..": "Acchcuu. Don't worry, we won't tell them..",
      "Please tell us why you are blocking": "Please tell us why you are blocking",
      "Blocking": "Blocking",
      "Languages": "Languages",
      "Block": "Block",
      "Report": "Report",
      "Continue": "Continue",
      "Why did you report this user?": "Why did you report this user?",
      "Don't worry we won't tell them": "Don't worry, we won't tell them",
      "Please tell us why you are reporting": "Please tell us why you are reporting",
      "Reporting": "Reporting",
      "Send Gifts": "Send Gifts",
      "Request Date": "Request Date",
      "Ifsc Code": "Ifsc Code",
      "Account Name": "Account Name",
      "pay by": "pay by",
      "Amount": "Amount",
      "Number of coin": "Number of coin",
      "Payout id": "Payout id",
    }
  },
  ar: {
    translation: {
      home: 'الصفحة الرئيسية',
      about: 'معلومات عنا',
      contact: 'اتصل بنا',
      details: 'صفحة التفاصيل',
      lorem: 'لوريم إيبسوم دولور سيت أميت كونسيكتتور أديبيسيسينغ إيليت. موليستيا إيوستو هاروم تينيتور ماجني لابوريُوسام، مينيما فوجا، سابينتي كولبا سولوتا توتام نون بورو. سوسيبِيت ديستينكتيو فيوجيت فينِيام ناتوس لابوروم أوت أوت؟',
      "Log Out": "تسجيل الخروج",
      "Account & Security": "الحساب والأمان",
      "Buy Coin": "شراء العملة",
      "Explore": "استكشاف",
      "Settings": "الإعدادات",
      "Wallet": "المحفظة",
      "Edit": "تعديل",
      "You're Activated Membersip": "أنت عضو نشط",
      "Active": "نشط",
      "Enjoy premium and match anyway": "استمتع بالعضوية المميزة وتطابقها على أي حال",
      "Is Verify": "هل التحقق من",
      "Panding": "معلق",
      "Upload Photo": "تحميل صورة",
      "persons Online": "شخص متصل",
      "There are": "هناك",
      "Loading...": "جاري التحميل...",
      "Filter": "تصفية",
      "Reset": "إعادة تعيين",
      "Premium": "Premium",
      "Upgrade Now": "قم بالترقية الآن",
      "Apply": "تطبيق",
      "Add": "أضف",
      "Add Your Favorite Photo": "أضف صورتك المفضلة",
      "Upload": "قم بتحميلها",
      "shortly!": "قريبًا!",
      "selfies and will get back to you": "صور شخصية وسنتواصل معك",
      "We are currently reviewing your": "نقوم حاليًا بمراجعة",
      "Review": "مراجعتك",
      "Verification Under": "التحقق بموجب",
      "No Any Passed Profiles...": "لا توجد ملفات تعريف ناجحة...",
      "No Add Any Favourite Profiles...": "لا توجد ملفات تعريف مفضلة...",
      "No Any User Like You...": "لا يوجد أي مستخدم مثلك...",
      "No Any Match Profiles...": "لا توجد ملفات تعريف مطابقة...",
      "Passed": "تم النجاح",
      "Favourite": "مفضلة",
      "Like Me": "أعجبني",
      "New Match": "مطابقة جديدة",
      "Filter & Show": "تصفية وإظهار",
      "Verify Profile": "التحقق من الملف الشخصي",
      "Distance Range": "نطاق المسافة",
      "Age": "العمر",
      "Serach Preference": "تفضيلات البحث",
      "Interests": "الاهتمامات",
      "Langusges I Know": "اللغات التي أعرفها",
      "Religion": "الدين",
      "Relationship Goals": "أهداف العلاقة",
      "Verify Profile": "التحقق من الملف الشخصي",
      "Update": "تحديث",
      "Distance Preference": "تفضيلات المسافة",
      "Birthdate": "تاريخ الميلاد",
      "Password": "كلمة المرور",
      "Mobile Number": "رقم الهاتف المحمول",
      "Email": "البريد الإلكتروني",
      "Nick Name": "الاسم المستعار",
      "Update your personal photos here.": "قم بتحديث صورك الشخصية هنا.",
      "Height": "ارتفاع",
      "CM": "سم",
      "Bio": "السيرة الذاتية",
      "Gender": "جنس",
      "No any Transaction...": "لا توجد أي معاملة...",
      "Transaction": "المعاملة",
      "Top-up": "إعادة التعبئة",
      "Total Balance": "إجمالي الرصيد",
      "Coin Buying & Info": "شراء العملات المعدنية والمعلومات",
      "Withdraw": "سحب",
      "Select Coin Package": "اختيار حزمة العملات المعدنية",
      "History": "التاريخ",
      "Your Coin": "عملتك المعدنية",
      "Coins cannot be transferred to any users...": "لا يمكن تحويل العملات إلى أي مستخدمين...",
      "Coins can be withdrawn eith the described method only...": "يمكن سحب العملات بالطريقة الموضحة فقط...",
      "Coins are credited to your Coin balance only...": "يتم إضافة العملات إلى رصيد العملات الخاص بك فقط...",
      "Coins can be used with all payment modes...": "يمكن استخدام العملات مع جميع طرق الدفع...",
      "Coins don't have any expiry date...": "لا يوجد تاريخ انتهاء صلاحية للعملات...",
      "Coin can be used for sending gifts only...": "يمكن استخدام العملات لإرسال الهدايا فقط...",
      "You need a minimum of 1000 coins to make a withdeawal...": "تحتاج إلى 1000 عملة على الأقل لإجراء عملية السحب...",
      "No any Coin History...": "لا يوجد تاريخ للعملة...",
      "Withdraw History": "تاريخ السحب",
      "Coin History": "تاريخ العملة",
      "The pepole you blocked are displayed here..": "يتم عرض الأشخاص الذين قمت بحظرهم هنا.",
      "Blocked Users": "المستخدمون المحظورون",
      "Get Started": "البدء",
      "Plans": "الخطط",
      "Number Of Coin": "عدد العملات",
      "Paypal": "بايبال",
      "BANK Transfer": "التحويل البنكي",
      "Proceed": "متابعة",
      "Cancel": "إلغاء",
      "Please Email ID": "يرجى إدخال معرف البريد الإلكتروني",
      "Email ID": "معرف البريد الإلكتروني",
      "Please Enter IFSC Code": "يرجى إدخال رمز  آي إف إس سي",
      "IFSC Code": "رمز آي إف إس سي",
      "Please Enter Account Holder Name": "يرجى إدخال اسم صاحب الحساب",
      "Account Holder Name": "اسم صاحب الحساب",
      "Please Enter Bank Name": "يرجى إدخال اسم البنك",
      "Bank Name": "اسم البنك",
      "Please Enter Account Number": "يرجى إدخال رقم الحساب",
      "Account Number": "رقم الحساب",
      "Please Enter UPI": "يرجى إدخال UPI",
      "UPI": " يو.بي.آي",
      "Select Type": "اختر النوع",
      "Please Enter Amount": "يرجى إدخال المبلغ",
      "Yes, Block": "نعم بلوك",
      "Cancel": "يلغي",
      "You can unblock them anytime in Settings.": "يمكنك إلغاء حظرهم في أي وقت في الإعدادات.",
      "They will not be notified if you block them.": "لن يتم إخطارهم إذا قمت بحظرهم.",
      "They will not be able to find your profile and send you messages.": "لن يتمكنوا من العثور على ملفك الشخصي وإرسال رسائل إليك.",
      "Acchcuu. Don't worry we won't tell them..": "أشكيو. لا تقلق لن نخبرهم..",
      "Please tell us why you are blocking": "من فضلك أخبرنا عن سبب الحظر",
      "Blocking": "الحظر",
      "Languages": "اللغات",
      "Block": "حاجز",
      "Report": "التقارير",
      "Continue": "يكمل",
      "Why did you rport this user?": "لماذا قمت بالإبلاغ عن هذا المستخدم؟",
      "Don't worry we won't tell them": "لا تقلق لن نخبرهم",
      "Pelease tell us why you are reporting": "من فضلك أخبرنا لماذا تقوم بالإبلاغ",
      "Reporting": "إعداد التقارير",
      "Send Gifts": "إرسال الهدايا",
      "Request Date": "تاريخ الطلب",
      "Ifsc Code": "رمز IFSC",
      "Account Name": "اسم الحساب",
      "pay by": "الدفع بواسطة",
      "Amount": "المبلغ",
      "Number of coin": "عدد العملات",
      "Payout id": "معرف الدفع"
    }
  },
  // he: {
  //   translation: {
  //     home: 'בית',
  //     about: 'אודות',
  //     contact: 'צור קשר',
  //     details: 'דף פרטים',
  //     lorem: 'לורם איפסום דולור סיט אמאט קונסקטטור אדיפיסיסינג אליט.',
  //     "Log Out": "התנתק",
  //     "Account & Security": "חשבון ואבטחה",
  //     "Buy Coin": "קנה מטבע",
  //     "Explore": "לַחקוֹר",
  //     "Settings": "הגדרות",
  //     "Wallet": "אַרְנָק",
  //     "Edit": "לַעֲרוֹך",
  //     "You're Activated Membersip": "אתה חברות פעיל",
  //     "Active": "פָּעִיל",
  //     "Enjoy premium and match anyway": "תהנה מפרימיום והתאמה בכל מקרה",
  //     "Is Verify": "האם Verify",
  //     "Panding": "תָלוּי וְעוֹמֵד",
  //     "Upload Photo": "העלה תמונה",
  //     "persons Online": "אדם מקוון",
  //     "There are": "יֵשׁ",
  //     "Loading...": "טְעִינָה...",
  //     "Filter": "לְסַנֵן",
  //     "Reset": "אִתחוּל",
  //     "Premium": "פּרֶמיָה",
  //     "Upgrade Now": "שדרג עכשיו",
  //     "On": "עַל",
  //     "On Yout Location": "ב-Yout Location",
  //     "Send": "לִשְׁלוֹחַ",
  //     "Apply": "לִפְנוֹת",
  //     "Reset": "אִתחוּל",
  //     "Verify": "לְאַמֵת",
  //     "Unverify": "בטל את האימות",
  //     "Verify Profile": "פרופיל מאומת",
  //     "Relationship Goals": "מטרות מערכת היחסים",
  //     "Religion": "דָת",
  //     "km": 'ק"מ',
  //     "Add": "לְהוֹסִיף",
  //     "Add Your Favorite Photo": "הוסף את התמונה האהובה עליך",
  //     "Upload": "העלה",
  //     "shortly!": "בְּקָרוּב!",
  //     "selfies and will get back to you": "סלפי ויחזור אליך",
  //     "We are currently reviewing your": "אנו בודקים כעת את שלך",
  //     "Review": "סְקִירָה",
  //     "Verification Under": "אימות תחת",
  //     "No Any Passed Profiles...": "אין פרופילים שעברו...",
  //     "No Add Any Favourite Profiles...": "לא הוסף פרופילים מועדפים...",
  //     "No Any User Like You...": "אין משתמש כמוך...",
  //     "No Any Match Profiles...": "אין פרופילי התאמה...",
  //     "Passed": "עבר",
  //     "Favourite": "מוּעֲדָף",
  //     "Like Me": "כמוני",
  //     "New Match": "התאמה חדשה",
  //     "Filter & Show": "סינון והצג",
  //     "Verify Profile": "אמת את הפרופיל",
  //     "Distance Range": "טווח מרחק",
  //     "Age": "גִיל",
  //     "Serach Preference": "העדפות חיפוש",
  //     "Interests": "תחומי עניין",
  //     "Langusges I Know": "שפות שאני יודע",
  //     "Religion": "דָת",
  //     "Relationship Goals": "מטרות מערכת היחסים",
  //     "Verify Profile": "אמת את הפרופיל",
  //     "Update": "לְעַדְכֵּן",
  //     "Distance Preference": "העדפת מרחק",
  //     "Birthdate": "תאריך לידה",
  //     "Password": "סִיסמָה",
  //     "Mobile Number": "מספר נייד",
  //     "Email": "אֶלֶקטרוֹנִי",
  //     "Nick Name": "שם ניק",
  //     "Update your personal photos here.": "עדכן את התמונות האישיות שלך כאן.",
  //     "Height": "גוֹבַה",
  //     "Bio": "ביו",
  //     "Gender": "מִין",
  //     "No any Transaction...": "אין שום עסקה...",
  //     "Transaction": "עִסקָה",
  //     "Top-up": "הטעינה",
  //     "Total Balance": "יתרה כוללת",
  //     "Coin Buying & Info": "קניית מטבעות ומידע",
  //     "Withdraw": "לָסֶגֶת",
  //     "Select Coin Package": "בחר בחבילת מטבעות",
  //     "History": "הִיסטוֹרִיָה",
  //     "Your Coin": "המטבע שלך",
  //     "Coins cannot be transferred to any users...": "לא ניתן להעביר מטבעות לאף משתמש...",
  //     "Coins can be withdrawn eith the described method only...": "ניתן למשוך מטבעות בשיטה המתוארת בלבד...",
  //     "Coins are credited to your Coin balance only...": "מטבעות זוכים ליתרת המטבעות שלך בלבד...",
  //     "Coins can be used with all payment modes...": "ניתן להשתמש במטבעות בכל מצבי התשלום...",
  //     "Coins don't have any expiry date...": "למטבעות אין תאריך תפוגה...",
  //     "Coin can be used for sending gifts only...": "ניתן להשתמש במטבע לשליחת מתנות בלבד...",
  //     "You need a minimum of 1000 coins to make a withdeawal...": "אתה צריך לפחות 1000 מטבעות כדי לבצע משיכה...",
  //     "No any Coin History...": "אין היסטוריית מטבעות...",
  //     "Withdraw History": "למשוך את ההיסטוריה",
  //     "Coin History": "היסטוריית המטבעות",
  //     "The pepole you blocked are displayed here..": "האנשים שחסמת מוצגים כאן..",
  //     "Blocked Users": "משתמשים חסומים",
  //     "Get Started": "התחל",
  //     "Plans": "תוכניות",
  //     "Paypal": "פייפאל",
  //     "BANK Transfer": "העברה בנקאית",
  //     "Proceed": "לְהִתְקַדֵם",
  //     "Cancel": "לְבַטֵל",
  //     "Please Email ID": "נא לשלוח אימייל מזהה",
  //     "Email ID": "מזהה אימייל",
  //     "Please Enter IFSC Code": "נא להזין קוד איי אף אס סי",
  //     "IFSC Code": "קוד איי אף אס סי",
  //     "Please Enter Account Holder Name": "נא להזין את שם בעל החשבון",
  //     "Account Holder Name": "שם בעל החשבון",
  //     "Please Enter Bank Name": "נא להזין את שם הבנק",
  //     "Bank Name": "שם הבנק",
  //     "Please Enter Account Number": "נא להזין מספר חשבון",
  //     "Account Number": "מספר חשבון",
  //     "Please Enter UPI": "נא להזין UPI",
  //     "UPI": " יוא.פי.איי",
  //     "Select Type": "בחר סוג",
  //     "Please Enter Amount": "נא להזין סכום",
  //   }
  // },
  sp: {
    translation: {
      "home": "Inicio",
      "Coin History": "Historial de Monedas",
      "Withdraw History": "Historial de Retiros",
      "about": "Acerca de Nosotros",
      "contact": "Contáctanos",
      "details": "Página de Detalles",
      "lorem": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestias iusto harum tenetur magni laboriosam, minima fuga, sapiente culpa soluta totam non porro. Suscipit distinctio fugit veniam natus laborum ut aut?",
      "Log Out": "Cerrar sesión",
      "Account & Security": "Cuenta y Seguridad",
      "Buy Coin": "Comprar Monedas",
      "Explore": "Explorar",
      "Settings": "Configuraciones",
      "Wallet": "Billetera",
      "Edit": "Editar",
      "You're Activated Membersip": "Tu Membresía Está Activada",
      "Active": "Activo",
      "Enjoy premium and match anyway": "Disfruta de la membresía premium y haz coincidir de todos modos",
      "Is Verify": "Está Verificado",
      "Panding": "Pendiente",
      "Upload Photo": "Subir Foto",
      "persons Online": "personas en línea",
      "There are": "Hay",
      "Loading...": "Cargando...",
      "Filter": "Filtrar",
      "Reset": "Restablecer",
      "Premium": "Premium",
      "Upgrade Now": "Mejorar Ahora",
      "Apply": "Aplicar",
      "Add": "Agregar",
      "Add Your Favorite Photo": "Agrega Tu Foto Favorita",
      "Upload": "Subir",
      "shortly": "en breve",
      "selfies and will get back to you": "selfies y te responderemos",
      "We are currently reviewing your": "Actualmente estamos revisando tu",
      "Review": "Revisión",
      "Verification Under": "Verificación en Proceso",
      "No Any Passed Profiles...": "No Hay Perfiles Aprobados...",
      "No Add Any Favourite Profiles...": "No Has Agregado Perfiles Favoritos...",
      "No Any User Like You...": "No Hay Usuarios Que Te Gusten...",
      "No Any Match Profiles...": "No Hay Perfiles Coincidentes...",
      "Passed": "Aprobado",
      "Favourite": "Favorito",
      "Like Me": "Me Gusta",
      "New Match": "Nueva Coincidencia",
      "Filter & Show": "Filtrar y Mostrar",
      "Verify Profile": "Verificar Perfil",
      "Distance Range": "Rango de Distancia",
      "Age": "Edad",
      "Serach Preference": "Preferencia de Búsqueda",
      "Interests": "Intereses",
      "Langusges I Know": "Idiomas que Conozco",
      "Religion": "Religión",
      "Relationship Goals": "Metas de Relación",
      "Verify Profile": "Verificar Perfil",
      "Update": "Actualizar",
      "Distance Preference": "Preferencia de Distancia",
      "Birthdate": "Fecha de Nacimiento",
      "Password": "Contraseña",
      "Mobile Number": "Número de Teléfono",
      "Email": "Correo Electrónico",
      "Nick Name": "Apodo",
      "Update your personal photos here.": "Actualiza tus fotos personales aquí.",
      "Height": "Altura",
      "CM": "CM",
      "Bio": "Biografía",
      "Gender": "Género",
      "No any Transaction...": "No Hay Transacciones...",
      "Transaction": "Transacción",
      "Top-up": "Recargar",
      "Total Balance": "Saldo Total",
      "Coin Buying & Info": "Compra de Monedas e Información",
      "Withdraw": "Retirar",
      "Select Coin Package": "Seleccionar Paquete de Monedas",
      "History": "Historial",
      "Your Coin": "Tus Monedas",
      "Coins cannot be transferred to any users...": "Las monedas no se pueden transferir a otros usuarios...",
      "Coins can be withdrawn eith the described method only...": "Las monedas solo se pueden retirar mediante el método descrito...",
      "Coins are credited to your Coin balance only...": "Las monedas se acreditan solo a tu saldo de monedas...",
      "Coins can be used with all payment modes...": "Las monedas se pueden usar con todos los modos de pago...",
      "Coins don't have any expiry date...": "Las monedas no tienen fecha de caducidad...",
      "Coin can be used for sending gifts only...": "Las monedas solo se pueden usar para enviar regalos...",
      "You need a minimum of 1000 coins to make a withdeawal...": "Necesitas un mínimo de 1000 monedas para hacer un retiro...",
      "The pepole you blocked are displayed here..": "Las personas que bloqueaste se muestran aquí...",
      "Blocked Users": "Usuarios Bloqueados",
      "Get Started": "Comenzar",
      "Plans": "Planes",
      "Number Of Coin": "Número de Monedas",
      "Paypal": "Paypal",
      "BANK Transfer": "Transferencia Bancaria",
      "Proceed": "Proceder",
      "Cancel": "Cancelar",
      "Please Email ID": "Por favor, ingresa tu ID de correo electrónico",
      "Email ID": "ID de Correo Electrónico",
      "Please Enter IFSC Code": "Por favor ingresa el código IFSC",
      "IFSC Code": "Código IFSC",
      "Please Enter Account Holder Name": "Por favor ingresa el nombre del titular de la cuenta",
      "Account Holder Name": "Nombre del Titular de la Cuenta",
      "Please Enter Bank Name": "Por favor ingresa el nombre del banco",
      "Bank Name": "Nombre del Banco",
      "Please Enter Account Number": "Por favor ingresa el número de cuenta",
      "Account Number": "Número de Cuenta",
      "Please Enter UPI": "Por favor ingresa el UPI",
      "UPI": "UPI",
      "Select Type": "Seleccionar Tipo",
      "Please Enter Amount": "Por favor ingresa el monto",
      "Yes, Block": "Sí, Bloquear",
      "Cancel": "Cancelar",
      "You can unblock them anytime in Settings.": "Puedes desbloquearlos en cualquier momento en Configuración.",
      "They will not be notified if you block them.": "No serán notificados si los bloqueas.",
      "They will not be able to find your profile and send you messages.": "No podrán encontrar tu perfil ni enviarte mensajes.",
      "Acchcuu. Don't worry we won't tell them..": "Acchcuu. No te preocupes, no se lo diremos..",
      "Please tell us why you are blocking": "Por favor, dinos por qué los estás bloqueando",
      "Blocking": "Bloqueando",
      "Languages": "Idiomas",
      "Block": "Bloquear",
      "Report": "Informar",
      "Continue": "Continuar",
      "Why did you report this user?": "¿Por qué reportaste a este usuario?",
      "Don't worry we won't tell them": "No te preocupes, no se lo diremos",
      "Please tell us why you are reporting": "Por favor, dinos por qué estás reportando",
      "Reporting": "Reportando",
      "Send Gifts": "Enviar regalos",
      "Request Date": "Fecha de solicitud",
      "Ifsc Code": "Código IFSC",
      "Account Name": "Nombre de la cuenta",
      "pay by": "pagar por",
      "Amount": "Cantidad",
      "Number of coin": "Número de monedas",
      "Payout id": "ID de pago"
    }
  },
  hi: {
    translation: {
      "home": "होम",
      "about": "हमारे बारे में",
      "Coin History": "सिक्का इतिहास",
      "Withdraw History": "निकासी इतिहास",
      "contact": "संपर्क करें",
      "details": "विवरण पृष्ठ",
      "lorem": "लोरेम इप्सम डोलर सिट एट कंसैक्टेचर एडीपिसिकिंग एलीट। मोलेस्टियास इउस्टो हारम टेनेटर मैग्नी लेबरिओसाम, मिनिमा फूगा, सापियेंटे कुल्पा सोलुटा टोटाम नॉन पॉरो। सुसेप्ट डिस्टिंक्टियो फुगीत वेनियम natus laborum ut aut?",
      "Log Out": "लॉग आउट",
      "Account & Security": "खाता और सुरक्षा",
      "Buy Coin": "सिक्के खरीदें",
      "Explore": "अन्वेषण करें",
      "Settings": "सेटिंग्स",
      "Wallet": "वॉलेट",
      "Edit": "संपादित करें",
      "You're Activated Membersip": "आपकी सदस्यता सक्रिय है",
      "Active": "सक्रिय",
      "Enjoy premium and match anyway": "प्रीमियम का आनंद लें और मैच करें",
      "Is Verify": "सत्यापित है",
      "Panding": "लंबित",
      "Upload Photo": "फ़ोटो अपलोड करें",
      "persons Online": "ऑनलाइन व्यक्ति",
      "There are": "यहां हैं",
      "Loading...": "लोड हो रहा है...",
      "Filter": "फ़िल्टर",
      "Reset": "रीसेट करें",
      "Premium": "प्रीमियम",
      "Upgrade Now": "अब अपग्रेड करें",
      "Apply": "लागू करें",
      "Add": "जोड़ें",
      "Add Your Favorite Photo": "अपनी पसंदीदा फ़ोटो जोड़ें",
      "Upload": "अपलोड करें",
      "shortly": "जल्द ही",
      "selfies and will get back to you": "सेल्फी और हम आपसे जल्द संपर्क करेंगे",
      "We are currently reviewing your": "हम वर्तमान में आपके",
      "Review": "समीक्षा",
      "Verification Under": "सत्यापन प्रक्रिया में",
      "No Any Passed Profiles...": "कोई पास हुए प्रोफाइल नहीं...",
      "No Add Any Favourite Profiles...": "कोई पसंदीदा प्रोफाइल नहीं जोड़ी गई...",
      "No Any User Like You...": "आपको पसंद करने वाला कोई उपयोगकर्ता नहीं...",
      "No Any Match Profiles...": "कोई मैच प्रोफाइल नहीं...",
      "Passed": "पारित",
      "Favourite": "पसंदीदा",
      "Like Me": "मुझे पसंद करें",
      "New Match": "नया मैच",
      "Filter & Show": "फ़िल्टर और दिखाएं",
      "Verify Profile": "प्रोफाइल सत्यापित करें",
      "Distance Range": "दूरी सीमा",
      "Age": "उम्र",
      "Serach Preference": "खोज प्राथमिकता",
      "Interests": "रुचियां",
      "Langusges I Know": "भाषाएं जो मुझे आती हैं",
      "Religion": "धर्म",
      "Relationship Goals": "संबंध लक्ष्य",
      "Verify Profile": "प्रोफाइल सत्यापित करें",
      "Update": "अपडेट करें",
      "Distance Preference": "दूरी प्राथमिकता",
      "Birthdate": "जन्मतिथि",
      "Password": "पासवर्ड",
      "Mobile Number": "मोबाइल नंबर",
      "Email": "ईमेल",
      "Nick Name": "निक नेम",
      "Update your personal photos here.": "यहां अपनी व्यक्तिगत फ़ोटो अपडेट करें।",
      "Height": "ऊंचाई",
      "CM": "सेंटीमीटर",
      "Bio": "बायो",
      "Gender": "लिंग",
      "No any Transaction...": "कोई लेन-देन नहीं...",
      "Transaction": "लेन-देन",
      "Top-up": "टॉप-अप",
      "Total Balance": "कुल शेष",
      "Coin Buying & Info": "सिक्का खरीदने और जानकारी",
      "Withdraw": "निकासी",
      "Select Coin Package": "सिक्का पैकेज चुनें",
      "History": "इतिहास",
      "Your Coin": "आपका सिक्का",
      "Coins cannot be transferred to any users...": "सिक्कों को किसी अन्य उपयोगकर्ता को स्थानांतरित नहीं किया जा सकता है...",
      "Coins can be withdrawn eith the described method only...": "सिक्कों को केवल वर्णित विधि से निकाला जा सकता है...",
      "Coins are credited to your Coin balance only...": "सिक्के केवल आपके सिक्का बैलेंस में जमा किए जाते हैं...",
      "Coins can be used with all payment modes...": "सिक्कों का उपयोग सभी भुगतान मोड्स के साथ किया जा सकता है...",
      "Coins don't have any expiry date...": "सिक्कों की कोई समाप्ति तिथि नहीं होती है...",
      "Coin can be used for sending gifts only...": "सिक्कों का उपयोग केवल उपहार भेजने के लिए किया जा सकता है...",
      "You need a minimum of 1000 coins to make a withdeawal...": "निकासी करने के लिए आपको कम से कम 1000 सिक्कों की आवश्यकता होती है...",
      "The pepole you blocked are displayed here..": "यहां वह लोग दिखाई देंगे जिन्हें आपने ब्लॉक किया है...",
      "Blocked Users": "ब्लॉक किए गए उपयोगकर्ता",
      "Get Started": "शुरू करें",
      "Plans": "योजनाएं",
      "Number Of Coin": "सिक्कों की संख्या",
      "Paypal": "पेपाल",
      "BANK Transfer": "बैंक ट्रांसफर",
      "Proceed": "आगे बढ़ें",
      "Cancel": "रद्द करें",
      "Please Email ID": "कृपया ईमेल आईडी दर्ज करें",
      "Email ID": "ईमेल आईडी",
      "Please Enter IFSC Code": "कृपया IFSC कोड दर्ज करें",
      "IFSC Code": "IFSC कोड",
      "Please Enter Account Holder Name": "कृपया खाता धारक का नाम दर्ज करें",
      "Account Holder Name": "खाता धारक का नाम",
      "Please Enter Bank Name": "कृपया बैंक का नाम दर्ज करें",
      "Bank Name": "बैंक का नाम",
      "Please Enter Account Number": "कृपया खाता संख्या दर्ज करें",
      "Account Number": "खाता संख्या",
      "Please Enter UPI": "कृपया UPI दर्ज करें",
      "UPI": "UPI",
      "Select Type": "प्रकार चुनें",
      "Please Enter Amount": "कृपया राशि दर्ज करें",
      "Yes, Block": "हाँ, ब्लॉक करें",
      "Cancel": "रद्द करें",
      "You can unblock them anytime in Settings.": "आप उन्हें किसी भी समय सेटिंग्स में अनब्लॉक कर सकते हैं।",
      "They will not be notified if you block them.": "अगर आप उन्हें ब्लॉक करते हैं तो उन्हें सूचित नहीं किया जाएगा।",
      "They will not be able to find your profile and send you messages.": "वे आपका प्रोफ़ाइल नहीं ढूंढ पाएंगे और आपको संदेश नहीं भेज पाएंगे।",
      "Acchcuu. Don't worry we won't tell them..": "अच्छू। चिंता मत करो, हम उन्हें नहीं बताएंगे..",
      "Please tell us why you are blocking": "कृपया हमें बताएं कि आप उन्हें क्यों ब्लॉक कर रहे हैं",
      "Blocking": "ब्लॉक करना",
      "Languages": "भाषाएँ",
      "Block": "ब्लॉक",
      "Report": "रिपोर्ट करें",
      "Continue": "जारी रखें",
      "Why did you report this user?": "आपने इस उपयोगकर्ता को क्यों रिपोर्ट किया?",
      "Don't worry we won't tell them": "चिंता मत करो, हम उन्हें नहीं बताएंगे",
      "Please tell us why you are reporting": "कृपया हमें बताएं कि आप क्यों रिपोर्ट कर रहे हैं",
      "Reporting": "रिपोर्ट करना",
      "Send Gifts": "उपहार भेजें",
      "Request Date": "अनुरोध तिथि",
      "Ifsc Code": "IFSC कोड",
      "Account Name": "खाता नाम",
      "pay by": "द्वारा भुगतान",
      "Amount": "राशि",
      "Number of coin": "सिक्कों की संख्या",
      "Payout id": "भुगतान आईडी"
    }
  },
  gu: {
    translation: {
      "home": "હોમ",
      "about": "અમારા વિશે",
      "contact": "સંપર્ક કરો",
      "details": "વિગત પૃષ્ઠ",
      "lorem": "લોરમ ઇપ્સમ ડોલોર સીટ એમેટ કોનસેક્ટેટુર એડિપિસિસિંગ એલિટ. મોલેસ્ટિયાસ ઈયુસ્ટો હરમ ટેનેટર મેગ્ની લેબોરિયોસામ, મિનિમા ફુગા, સાપિયેંટે કુલ્પા સોલ્યુટા ટોટામ નોન પોરો. સુસિપિટ ડિસ્ટિન્ક્ટિયો fugિt વેનિયમ નATUS laborum ઉત ઓટ?",
      "Log Out": "લોગ આઉટ",
      "Account & Security": "ખાતું અને સુરક્ષા",
      "Buy Coin": "કોઇન ખરીદો",
      "Explore": "એક્સપ્લોર",
      "Settings": "સેટિંગ્સ",
      "Wallet": "વૉલેટ",
      "Edit": "સંપાદિત કરો",
      "You're Activated Membersip": "તમારી સભ્યતા સક્રિય છે",
      "Active": "સક્રિય",
      "Enjoy premium and match anyway": "પ્રીમિયમનો આનંદ લો અને મેચિંગ ચાલુ રાખો",
      "Is Verify": "સत्यાપિત છે",
      "Panding": "પ્રતિક્રિયા માંગો",
      "Upload Photo": "ફોટો અપલોડ કરો",
      "persons Online": "વ્યક્તિઓ ઑનલાઇન",
      "There are": "અહીં છે",
      "Loading...": "લોડ થઈ રહ્યું છે...",
      "Filter": "ફિલ્ટર",
      "Reset": "પુનઃપ્રારંભ કરો",
      "Premium": "પ્રીમિયમ",
      "Upgrade Now": "હવે અપગ્રેડ કરો",
      "Apply": "લાગૂ કરો",
      "Add": "સંલગ્ન કરો",
      "Add Your Favorite Photo": "તમારી મનપસંદ ફોટો સંલગ્ન કરો",
      "Upload": "અપલોડ કરો",
      "shortly": "લવક",
      "selfies and will get back to you": "સેલ્ફી અને પરત સંપર્ક કરશું",
      "We are currently reviewing your": "અમે હાલમાં તમારી ચકાસણી કરી રહ્યા છીએ",
      "Review": "સમીક્ષા",
      "Verification Under": "સત્યાપન હેઠળ",
      "No Any Passed Profiles...": "કોઈ પણ પાસ થયેલી પ્રોફાઇલ નથી...",
      "No Add Any Favourite Profiles...": "કોઈ પણ મનપસંદ પ્રોફાઇલ ઉમેરવામાં આવી નથી...",
      "No Any User Like You...": "તમારા જેવાં કોઈ યુઝર નથી...",
      "No Any Match Profiles...": "કોઈ પણ મેચ પ્રોફાઇલ નથી...",
      "Passed": "પાસ થયેલ",
      "Favourite": "પ્રિય",
      "Like Me": "મને પસંદ કરો",
      "New Match": "નવી મૅચ",
      "Filter & Show": "ફિલ્ટર અને બતાવો",
      "Verify Profile": "પ્રોફાઇલ ચકાસો",
      "Distance Range": "દૂરની શ્રેણી",
      "Age": "ઉમ્ર",
      "Serach Preference": "શોધ શ્રેષ્ઠતા",
      "Interests": "રુચિઓ",
      "Langusges I Know": "ભાષાઓ હું જાણું છું",
      "Religion": "ધર્મ",
      "Relationship Goals": "સંબંધ લક્ષ્ય",
      "Verify Profile": "પ્રોફાઇલ ચકાસો",
      "Update": "અપડેટ",
      "Distance Preference": "દૂરતા પસંદગી",
      "Birthdate": "જન્મતારીખ",
      "Password": "પાસવર્ડ",
      "Mobile Number": "મોબાઇલ નંબર",
      "Email": "ઈમેલ",
      "Nick Name": "નિક નામ",
      "Update your personal photos here.": "તમારી વ્યક્તિગત ફોટાઓ અહીં અપડેટ કરો.",
      "Height": "ઊંચાઈ",
      "CM": "સે.મી.",
      "Bio": "બાયો",
      "Gender": "લિંગ",
      "No any Transaction...": "કોઈપણ ટ્રાન્ઝેક્શન નથી...",
      "Transaction": "ટ્રાન્ઝેક્શન",
      "Top-up": "ટોપ-અપ",
      "Total Balance": "કુલ બેલેન્સ",
      "Coin Buying & Info": "કોઈન ખરીદી અને માહિતી",
      "Withdraw": "કેશ ખીંચો",
      "Select Coin Package": "કોઈન પેકેજ પસંદ કરો",
      "History": "ઈતિહાસ",
      "Your Coin": "તમારો કોઈન",
      "Coins cannot be transferred to any users...": "કોઈન્સને કોઈપણ યૂઝર પર ટ્રાન્સફર કરી શકાય નહીં...",
      "Coins can be withdrawn eith the described method only...": "કોઈન્સને ફક્ત વર્ણવેલ પદ્ધતિથી જ ઉપાડવા કરી શકાય છે...",
      "Coins are credited to your Coin balance only...": "કોઈન્સ તમારાCoin બેલેન્સમાં જ ક્રેડિટ થાય છે...",
      "Coins can be used with all payment modes...": "કોઈન્સને બધા પેમેન્ટ મોડ સાથે ઉપયોગ કરી શકાય છે...",
      "Coins don't have any expiry date...": "કોઈન્સની કોઈ સમાપ્તી તારીખ નથી...",
      "Coin can be used for sending gifts only...": "કોઈન ફક્ત ગિફ્ટ મોકલવા માટે ઉપયોગ કરી શકાય છે...",
      "You need a minimum of 1000 coins to make a withdeawal...": "તમને કેશ ઉપાડવા માટે ઓછામાં ઓછા 1000 કોઈન્સની જરૂર છે...",
      "The pepole you blocked are displayed here..": "જેમને તમે બ્લોક કર્યા છે તે લોકો અહીં દર્શાવ્યા છે..",
      "Blocked Users": "બ્લોક કરેલ યુઝર્સ",
      "Get Started": "પ્રારંભ કરો",
      "Plans": "યોજનાઓ",
      "Number Of Coin": "કોઈન ની સંખ્યા",
      "Paypal": "પેપાલ",
      "BANK Transfer": "બેંક ટ્રાન્સફર",
      "Proceed": "આગળ વધો",
      "Cancel": "રદ્દ કરો",
      "Please Email ID": "કૃપા કરીને ઇમેઇલ આઈડી દાખલ કરો",
      "Email ID": "ઇમેઇલ આઈડી",
      "Please Enter IFSC Code": "કૃપા કરીને IFSC કોડ દાખલ કરો",
      "IFSC Code": "IFSC કોડ",
      "Please Enter Account Holder Name": "કૃપા કરીને ખાતાધારકનું નામ દાખલ કરો",
      "Account Holder Name": "ખાતાધારકનું નામ",
      "Please Enter Bank Name": "કૃપા કરીને બેંકનું નામ દાખલ કરો",
      "Bank Name": "બેંકનું નામ",
      "Please Enter Account Number": "કૃપા કરીને ખાતાનું નંબરી દાખલ કરો",
      "Account Number": "ખાતું નં",
      "Please Enter UPI": "કૃપા કરીને UPI દાખલ કરો",
      "UPI": "UPI",
      "Select Type": "પ્રકાર પસંદ કરો",
      "Please Enter Amount": "કૃપા કરીને રકમ દાખલ કરો",
      "Coin History": "કોઈન ઇતિહાસ",
      "Withdraw History": "કેશ ઉપાડ ઈતિહાસ",
      "Yes, Block": "હાં, બ્લૉક કરો",
      "Cancel": "રદ કરો",
      "You can unblock them anytime in Settings.": "તમેઓને ક્યારે પણ સેટિંગ્સમાં અનબ્લૉક કરી શકો છો.",
      "They will not be notified if you block them.": "જો તમે તેમને બ્લૉક કરો છો, તો તેમને સૂચિત કરવામાં આવશે નહીં.",
      "They will not be able to find your profile and send you messages.": "તેઓ તમારી પ્રોફાઇલ શોધી શકશે નહીં અને તમને સંદેશા મોકલી શકશે નહીં.",
      "Acchcuu. Don't worry we won't tell them..": "અચ્છુ. ચિંતાવિહોણા રહો, અમે તેમને નથી કહેતા..",
      "Please tell us why you are blocking": "કૃપા કરીને અમને કહો કે તમે કેમ બ્લૉક કરી રહ્યા છો",
      "Blocking": "બ્લૉક કરવું",
      "Languages": "ભાષાઓ",
      "Block": "બ્લૉક",
      "Report": "અહેવાલ કરો",
      "Continue": "આગળ વધો",
      "Why did you report this user?": "તમે આ વપરાશકર્તાને કેમ અહેવાલ કર્યો?",
      "Don't worry we won't tell them": "ચિંતાવિહોણા રહો, અમે તેમને નથી કહેતા",
      "Please tell us why you are reporting": "કૃપા કરીને અમને કહો કે તમે કેમ અહેવાલ કરી રહ્યા છો",
      "Reporting": "અહેવાલ કરવું",
      "Send Gifts": "ભેટ મોકલો",
      "Request Date": "વિનંતી તારીખ",
      "Ifsc Code": "IFSC કોડ",
      "Account Name": "ખાતા નું નામ",
      "pay by": "દ્વારા ચૂકવણી",
      "Amount": "રકમ",
      "Number of coin": "સિક્કાઓની સંખ્યા",
      "Payout id": "પેઆઉટ આઈડી"
    }
  },
  af: {
    translation: {
      "home": "Tuissblad",
      "about": "Oor ons",
      "contact": "Kontak ons",
      "details": "Beskrywingsblad",
      "lorem": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Molestias iusto harum tenetur magni laboriosam, minima fuga, sapiente culpa soluta totam non poro. Suscipit distinctio fugit veniam natus laborum ut aut?",
      "Log Out": "Teken uit",
      "Account & Security": "Rekening en sekuriteit",
      "Buy Coin": "Koop munt",
      "Explore": "Verken",
      "Settings": "Instellings",
      "Wallet": "Beursie",
      "Edit": "Wysig",
      "You're Activated Membership": "Jou lidmaatskap is geaktiveer",
      "Active": "Aktief",
      "Enjoy premium and match anyway": "Geniet premium en hou aan om te match",
      "Is Verify": "Geverifieer",
      "Panding": "Wag vir reaksie",
      "Upload Photo": "Laai foto op",
      "persons Online": "Mense aanlyn",
      "There are": "Daar is",
      "Loading...": "Laai besig...",
      "Filter": "Filtreer",
      "Reset": "Herstel",
      "Premium": "Premium",
      "Upgrade Now": "Opgradeer nou",
      "Apply": "Toepassing",
      "Add": "Voeg by",
      "Add Your Favorite Photo": "Voeg jou gunsteling foto by",
      "Upload": "Laai op",
      "shortly": "Binnekort",
      "selfies and will get back to you": "Selfies en ons sal weer met jou kontak maak",
      "We are currently reviewing your": "Ons hersien tans jou",
      "Review": "Hersiening",
      "Verification Under": "Verifikasie onder",
      "No Any Passed Profiles...": "Geen goedgekeurde profiele nie...",
      "No Add Any Favourite Profiles...": "Geen gunsteling profiele bygevoeg nie...",
      "No Any User Like You...": "Geen gebruikers soos jy nie...",
      "No Any Match Profiles...": "Geen pas profiel nie...",
      "Passed": "Deur",
      "Favourite": "Gunsteling",
      "Like Me": "Soos my",
      "New Match": "Nuwe wedstryd",
      "Filter & Show": "Filtreer en wys",
      "Verify Profile": "Verifieer profiel",
      "Distance Range": "Afstand reeks",
      "Age": "Leeftijd",
      "Serach Preference": "Soek voorkeur",
      "Interests": "Belangstellings",
      "Langusges I Know": "Tale wat ek ken",
      "Religion": "Geloof",
      "Relationship Goals": "Verhoudings doelwitte",
      "Verify Profile": "Verifieer profiel",
      "Update": "Opdateer",
      "Distance Preference": "Afstand voorkeur",
      "Birthdate": "Geboortedatum",
      "Password": "Wagwoord",
      "Mobile Number": "Mobiele nommer",
      "Email": "E-pos",
      "Nick Name": "Bynaam",
      "Update your personal photos here.": "Werk jou persoonlike foto's hier op.",
      "Height": "Hoogte",
      "CM": "CM",
      "Bio": "Bio",
      "Gender": "Geslag",
      "No any Transaction...": "Geen transaksie nie...",
      "Transaction": "Transaksie",
      "Top-up": "Oplaai",
      "Total Balance": "Totale balans",
      "Coin Buying & Info": "Munt koop en inligting",
      "Withdraw": "Onttrek",
      "Select Coin Package": "Kies munt pakket",
      "History": "Geskiedenis",
      "Your Coin": "Jou munt",
      "Coins cannot be transferred to any users...": "Munte kan nie aan enige gebruikers oorgedra word nie...",
      "Coins can be withdrawn eith the described method only...": "Munte kan net met die beskryfde metode onttrek word...",
      "Coins are credited to your Coin balance only...": "Munte word net aan jou muntbalans gekrediteer...",
      "Coins can be used with all payment modes...": "Munte kan met alle betalingsmetodes gebruik word...",
      "Coins don't have any expiry date...": "Munte het geen vervaldatum nie...",
      "Coin can be used for sending gifts only...": "Munt kan net gebruik word om geskenke te stuur...",
      "You need a minimum of 1000 coins to make a withdeawal...": "Jy benodig 'n minimum van 1000 munte om 'n onttrekking te maak...",
      "The pepole you blocked are displayed here..": "Die mense wat jy geblokkeer het, word hier vertoon..",
      "Blocked Users": "Geblokkeerde gebruikers",
      "Get Started": "Begin",
      "Plans": "Planne",
      "Number Of Coin": "Aantal Munte",
      "Paypal": "Paypal",
      "BANK Transfer": "Bank oordrag",
      "Proceed": "Gaan voort",
      "Cancel": "Kanselleer",
      "Please Email ID": "Voer asseblief jou e-pos ID in",
      "Email ID": "E-pos ID",
      "Please Enter IFSC Code": "Voer asseblief IFSC kode in",
      "IFSC Code": "IFSC Kode",
      "Please Enter Account Holder Name": "Voer asseblief rekeninghouer se naam in",
      "Account Holder Name": "Rekeninghouer se naam",
      "Please Enter Bank Name": "Voer asseblief banknaam in",
      "Bank Name": "Bank Naam",
      "Please Enter Account Number": "Voer asseblief rekeningnommer in",
      "Account Number": "Rekening nommer",
      "Please Enter UPI": "Voer asseblief UPI in",
      "UPI": "UPI",
      "Select Type": "Kies tipe",
      "Please Enter Amount": "Voer asseblief bedrag in",
      "Coin History": "Munt Geskiedenis",
      "Withdraw History": "Onthoud Geskiedenis",
      "Yes, Block": "Ja, Blokkeer",
      "Cancel": "Kanselleer",
      "You can unblock them anytime in Settings.": "Jy kan hulle enige tyd in Instellings ontsper.",
      "They will not be notified if you block them.": "Hulle sal nie gekennig word as jy hulle blokkeer nie.",
      "They will not be able to find your profile and send you messages.": "Hulle sal nie jou profiel kan vind of vir jou boodskappe stuur nie.",
      "Acchcuu. Don't worry we won't tell them..": "Acchcuu. Moet nie bekommerd wees nie, ons gaan hulle nie vertel nie..",
      "Please tell us why you are blocking": "Gee asseblief aan ons die rede waarom jy hulle blokkeer",
      "Blocking": "Blokkeer",
      "Languages": "Tale",
      "Block": "Blokkeer",
      "Report": "Verslag",
      "Continue": "Gaan voort",
      "Why did you report this user?": "Waarom het jy hierdie gebruiker gemeld?",
      "Don't worry we won't tell them": "Moet nie bekommerd wees nie, ons gaan hulle nie vertel nie",
      "Please tell us why you are reporting": "Gee asseblief aan ons die rede waarom jy verslag doen",
      "Reporting": "Verslagdoening",
      "Send Gifts": "Stuur Geskenke",
      "Request Date": "Versoekdatum",
      "Ifsc Code": "IFSC Kode",
      "Account Name": "Rekeningnaam",
      "pay by": "betaal deur",
      "Amount": "Bedrag",
      "Number of coin": "Aantal munte",
      "Payout id": "Betalings-ID"
    }
  },
  be: {
    translation: {
      "home": "হোম",
      "about": "আমাদের সম্পর্কে",
      "contact": "যোগাযোগ করুন",
      "details": "বিবরণ পৃষ্ঠা",
      "lorem": "লোরেম ইপসাম ডোলর সিট অ্যামেট কনসেক্টেটুর অ্যাডিপিসিং এলিট। মোলেস্টিয়াস ইউস্টো হারম টেনেটর মাগ্নি লেবোরিয়োসাম, মিনিমা ফুগা, স্যাপিয়েন্টে কুল্পা সোলিউটা টোটাম নন পোও। সাসিপিট ডিস্টিঙ্কটিও ফুগিত ভেনিয়াম ন্যাটাস লাবোরাম উদ অট?",
      "Log Out": "লগ আউট",
      "Account & Security": "একাউন্ট এবং সুরক্ষা",
      "Buy Coin": "কয়েন কিনুন",
      "Explore": "এক্সপ্লোর",
      "Settings": "সেটিংস",
      "Wallet": "ওয়ালেট",
      "Edit": "এডিট করুন",
      "You're Activated Membership": "আপনার সদস্যপদ সক্রিয় হয়েছে",
      "Active": "সক্রিয়",
      "Enjoy premium and match anyway": "প্রীমিয়াম উপভোগ করুন এবং ম্যাচ চালিয়ে যান",
      "Is Verify": "ভেরিফাইড",
      "Panding": "প্রতিক্রিয়া অপেক্ষমাণ",
      "Upload Photo": "ফটো আপলোড করুন",
      "persons Online": "ব্যক্তি অনলাইনে",
      "There are": "এখানে আছে",
      "Loading...": "লোড হচ্ছে...",
      "Filter": "ফিল্টার",
      "Reset": "রিসেট",
      "Premium": "প্রীমিয়াম",
      "Upgrade Now": "এখন আপগ্রেড করুন",
      "Apply": "প্রয়োগ করুন",
      "Add": "যোগ করুন",
      "Add Your Favorite Photo": "আপনার প্রিয় ছবি যোগ করুন",
      "Upload": "আপলোড করুন",
      "shortly": "শীঘ্রই",
      "selfies and will get back to you": "সেলফি এবং আমরা আপনাকে আবার যোগাযোগ করব",
      "We are currently reviewing your": "আমরা বর্তমানে আপনার যাচাই করছি",
      "Review": "পর্যালোচনা",
      "Verification Under": "যাচাইয়ের অধীনে",
      "No Any Passed Profiles...": "কোনো পাস হওয়া প্রোফাইল নেই...",
      "No Add Any Favourite Profiles...": "কোনো প্রিয় প্রোফাইল যোগ করা হয়নি...",
      "No Any User Like You...": "আপনার মতো কোনো ব্যবহারকারী নেই...",
      "No Any Match Profiles...": "কোনো ম্যাচ প্রোফাইল নেই...",
      "Passed": "পাস হয়েছে",
      "Favourite": "পছন্দ",
      "Like Me": "আমায় পছন্দ করুন",
      "New Match": "নতুন ম্যাচ",
      "Filter & Show": "ফিল্টার এবং দেখান",
      "Verify Profile": "প্রোফাইল যাচাই করুন",
      "Distance Range": "দূরত্ব পরিসীমা",
      "Age": "বয়স",
      "Serach Preference": "অনুসন্ধান পছন্দ",
      "Interests": "আগ্রহ",
      "Langusges I Know": "যে ভাষাগুলি আমি জানি",
      "Religion": "ধর্ম",
      "Relationship Goals": "সম্পর্কের লক্ষ্য",
      "Verify Profile": "প্রোফাইল যাচাই করুন",
      "Update": "আপডেট",
      "Distance Preference": "দূরত্ব পছন্দ",
      "Birthdate": "জন্মতারিখ",
      "Password": "পাসওয়ার্ড",
      "Mobile Number": "মোবাইল নম্বর",
      "Email": "ইমেইল",
      "Nick Name": "নিকনেম",
      "Update your personal photos here.": "আপনার ব্যক্তিগত ছবি এখানে আপডেট করুন।",
      "Height": "উচ্চতা",
      "CM": "সেমি",
      "Bio": "বায়ো",
      "Gender": "লিঙ্গ",
      "No any Transaction...": "কোনো লেনদেন নেই...",
      "Transaction": "লেনদেন",
      "Top-up": "টপ-আপ",
      "Total Balance": "মোট ব্যালেন্স",
      "Coin Buying & Info": "কয়েন ক্রয় ও তথ্য",
      "Withdraw": "উত্তোলন",
      "Select Coin Package": "কয়েন প্যাকেজ নির্বাচন করুন",
      "History": "ইতিহাস",
      "Your Coin": "আপনার কয়েন",
      "Coins cannot be transferred to any users...": "কয়েন কোন ব্যবহারকারীর কাছে স্থানান্তর করা যাবে না...",
      "Coins can be withdrawn eith the described method only...": "কয়েন শুধুমাত্র বর্ণিত পদ্ধতিতে উত্তোলন করা যাবে...",
      "Coins are credited to your Coin balance only...": "কয়েন শুধুমাত্র আপনার কয়েন ব্যালেন্সে জমা হয়...",
      "Coins can be used with all payment modes...": "কয়েন সব পেমেন্ট মোডের সাথে ব্যবহার করা যেতে পারে...",
      "Coins don't have any expiry date...": "কয়েনের কোনো মেয়াদোত্তীর্ণ তারিখ নেই...",
      "Coin can be used for sending gifts only...": "কয়েন শুধুমাত্র উপহার পাঠানোর জন্য ব্যবহার করা যেতে পারে...",
      "You need a minimum of 1000 coins to make a withdeawal...": "আপনার উত্তোলনের জন্য ন্যূনতম ১০০০ কয়েন প্রয়োজন...",
      "The pepole you blocked are displayed here..": "আপনি যাদের ব্লক করেছেন তারা এখানে প্রদর্শিত হবে..",
      "Blocked Users": "ব্লক করা ব্যবহারকারীরা",
      "Get Started": "শুরু করুন",
      "Plans": "পরিকল্পনা",
      "Number Of Coin": "কয়েনের সংখ্যা",
      "Paypal": "পেপাল",
      "BANK Transfer": "ব্যাংক ট্রান্সফার",
      "Proceed": "আগে যান",
      "Cancel": "বাতিল করুন",
      "Please Email ID": "দয়া করে ইমেইল আইডি দিন",
      "Email ID": "ইমেইল আইডি",
      "Please Enter IFSC Code": "দয়া করে IFSC কোড দিন",
      "IFSC Code": "IFSC কোড",
      "Please Enter Account Holder Name": "দয়া করে অ্যাকাউন্ট হোল্ডারের নাম দিন",
      "Account Holder Name": "অ্যাকাউন্ট হোল্ডারের নাম",
      "Please Enter Bank Name": "দয়া করে ব্যাংকের নাম দিন",
      "Bank Name": "ব্যাংকের নাম",
      "Please Enter Account Number": "দয়া করে অ্যাকাউন্ট নম্বর দিন",
      "Account Number": "অ্যাকাউন্ট নম্বর",
      "Please Enter UPI": "দয়া করে UPI দিন",
      "UPI": "UPI",
      "Select Type": "টাইপ নির্বাচন করুন",
      "Please Enter Amount": "দয়া করে পরিমাণ দিন",
      "Coin History": "কয়েন ইতিহাস",
      "Withdraw History": "উত্তোলন ইতিহাস",
      "Yes, Block": "হ্যাঁ, ব্লক করুন",
      "Cancel": "বাতিল করুন",
      "You can unblock them anytime in Settings.": "আপনি যে কোন সময় সেটিংসে গিয়ে তাদের আনব্লক করতে পারেন।",
      "They will not be notified if you block them.": "আপনি যদি তাদের ব্লক করেন, তারা জানানো হবে না।",
      "They will not be able to find your profile and send you messages.": "তারা আপনার প্রোফাইল খুঁজে পাবে না এবং আপনাকে মেসেজ পাঠাতে পারবে না।",
      "Acchcuu. Don't worry we won't tell them..": "অচ্ছু। চিন্তা করবেন না, আমরা তাদের বলব না..",
      "Please tell us why you are blocking": "দয়া করে আমাদের বলুন আপনি কেন তাদের ব্লক করছেন",
      "Blocking": "ব্লক করা",
      "Languages": "ভাষাসমূহ",
      "Block": "ব্লক",
      "Report": "রিপোর্ট",
      "Continue": "চলুন",
      "Why did you report this user?": "আপনি কেন এই ব্যবহারকারীকে রিপোর্ট করেছেন?",
      "Don't worry we won't tell them": "চিন্তা করবেন না, আমরা তাদের বলব না",
      "Please tell us why you are reporting": "দয়া করে আমাদের বলুন আপনি কেন রিপোর্ট করছেন",
      "Reporting": "রিপোর্টিং",
      "Send Gifts": "উপহার পাঠান",
      "Request Date": "অনুরোধ তারিখ",
      "Ifsc Code": "IFSC কোড",
      "Account Name": "অ্যাকাউন্ট নাম",
      "pay by": "দ্বারা পেমেন্ট",
      "Amount": "পরিমাণ",
      "Number of coin": "মুদ্রার সংখ্যা",
      "Payout id": "পে আউট আইডি"
    }
  },
  in: {
    translation: {
      "home": "Beranda",
      "about": "Tentang kami",
      "contact": "Hubungi kami",
      "details": "Halaman detail",
      "lorem": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Molestias iusto harum tenetur magni laboriosam, minima fuga, sapiente culpa soluta totam non poro. Suscipit distinctio fugit veniam natus laborum ut aut?",
      "Log Out": "Keluar",
      "Account & Security": "Akun & Keamanan",
      "Buy Coin": "Beli Koin",
      "Explore": "Jelajahi",
      "Settings": "Pengaturan",
      "Wallet": "Dompet",
      "Edit": "Edit",
      "You're Activated Membership": "Keanggotaan Anda telah diaktifkan",
      "Active": "Aktif",
      "Enjoy premium and match anyway": "Nikmati premium dan lanjutkan pencocokan",
      "Is Verify": "Terverifikasi",
      "Panding": "Menunggu respons",
      "Upload Photo": "Unggah Foto",
      "persons Online": "Orang online",
      "There are": "Ada",
      "Loading...": "Memuat...",
      "Filter": "Filter",
      "Reset": "Reset",
      "Premium": "Premium",
      "Upgrade Now": "Upgrade Sekarang",
      "Apply": "Terapkan",
      "Add": "Tambah",
      "Add Your Favorite Photo": "Tambahkan Foto Favorit Anda",
      "Upload": "Unggah",
      "shortly": "Segera",
      "selfies and will get back to you": "Selfie dan kami akan menghubungi Anda kembali",
      "We are currently reviewing your": "Kami sedang meninjau",
      "Review": "Ulasan",
      "Verification Under": "Verifikasi dalam proses",
      "No Any Passed Profiles...": "Tidak ada profil yang lolos...",
      "No Add Any Favourite Profiles...": "Tidak ada profil favorit yang ditambahkan...",
      "No Any User Like You...": "Tidak ada pengguna seperti Anda...",
      "No Any Match Profiles...": "Tidak ada profil yang cocok...",
      "Passed": "Lolos",
      "Favourite": "Favorit",
      "Like Me": "Sukai Saya",
      "New Match": "Pencocokan Baru",
      "Filter & Show": "Filter & Tampilkan",
      "Verify Profile": "Verifikasi Profil",
      "Distance Range": "Rentang Jarak",
      "Age": "Usia",
      "Serach Preference": "Preferensi Pencarian",
      "Interests": "Minat",
      "Langusges I Know": "Bahasa yang saya kuasai",
      "Religion": "Agama",
      "Relationship Goals": "Tujuan Hubungan",
      "Verify Profile": "Verifikasi Profil",
      "Update": "Perbarui",
      "Distance Preference": "Preferensi Jarak",
      "Birthdate": "Tanggal Lahir",
      "Password": "Kata Sandi",
      "Mobile Number": "Nomor Ponsel",
      "Email": "Email",
      "Nick Name": "Nama Panggilan",
      "Update your personal photos here.": "Perbarui foto pribadi Anda di sini.",
      "Height": "Tinggi",
      "CM": "CM",
      "Bio": "Bio",
      "Gender": "Jenis Kelamin",
      "No any Transaction...": "Tidak ada transaksi...",
      "Transaction": "Transaksi",
      "Top-up": "Isi Ulang",
      "Total Balance": "Saldo Total",
      "Coin Buying & Info": "Pembelian Koin & Info",
      "Withdraw": "Tarik",
      "Select Coin Package": "Pilih Paket Koin",
      "History": "Riwayat",
      "Your Coin": "Koin Anda",
      "Coins cannot be transferred to any users...": "Koin tidak dapat dipindahkan ke pengguna mana pun...",
      "Coins can be withdrawn eith the described method only...": "Koin hanya dapat ditarik dengan metode yang dijelaskan...",
      "Coins are credited to your Coin balance only...": "Koin hanya dikreditkan ke saldo Koin Anda...",
      "Coins can be used with all payment modes...": "Koin dapat digunakan dengan semua mode pembayaran...",
      "Coins don't have any expiry date...": "Koin tidak memiliki tanggal kedaluwarsa...",
      "Coin can be used for sending gifts only...": "Koin hanya dapat digunakan untuk mengirim hadiah...",
      "You need a minimum of 1000 coins to make a withdeawal...": "Anda memerlukan minimal 1000 koin untuk melakukan penarikan...",
      "The pepole you blocked are displayed here..": "Orang yang Anda blokir ditampilkan di sini..",
      "Blocked Users": "Pengguna yang Diblokir",
      "Get Started": "Mulai",
      "Plans": "Paket",
      "Number Of Coin": "Jumlah Koin",
      "Paypal": "Paypal",
      "BANK Transfer": "Transfer Bank",
      "Proceed": "Lanjutkan",
      "Cancel": "Batal",
      "Please Email ID": "Harap masukkan ID Email",
      "Email ID": "ID Email",
      "Please Enter IFSC Code": "Harap masukkan Kode IFSC",
      "IFSC Code": "Kode IFSC",
      "Please Enter Account Holder Name": "Harap masukkan Nama Pemegang Rekening",
      "Account Holder Name": "Nama Pemegang Rekening",
      "Please Enter Bank Name": "Harap masukkan Nama Bank",
      "Bank Name": "Nama Bank",
      "Please Enter Account Number": "Harap masukkan Nomor Rekening",
      "Account Number": "Nomor Rekening",
      "Please Enter UPI": "Harap masukkan UPI",
      "UPI": "UPI",
      "Select Type": "Pilih Jenis",
      "Please Enter Amount": "Harap masukkan Jumlah",
      "Coin History": "Riwayat Koin",
      "Withdraw History": "Riwayat Penarikan",
      "Yes, Block": "Ya, Blokir",
      "Cancel": "Batal",
      "You can unblock them anytime in Settings.": "Anda dapat membuka blokir mereka kapan saja di Pengaturan.",
      "They will not be notified if you block them.": "Mereka tidak akan diberitahu jika Anda memblokir mereka.",
      "They will not be able to find your profile and send you messages.": "Mereka tidak akan dapat menemukan profil Anda dan mengirimkan pesan kepada Anda.",
      "Acchcuu. Don't worry we won't tell them..": "Acchcuu. Jangan khawatir, kami tidak akan memberi tahu mereka..",
      "Please tell us why you are blocking": "Tolong beri tahu kami mengapa Anda memblokir",
      "Blocking": "Memblokir",
      "Languages": "Bahasa",
      "Block": "Blokir",
      "Report": "Laporkan",
      "Continue": "Lanjutkan",
      "Why did you report this user?": "Mengapa Anda melaporkan pengguna ini?",
      "Don't worry we won't tell them": "Jangan khawatir, kami tidak akan memberi tahu mereka",
      "Please tell us why you are reporting": "Tolong beri tahu kami mengapa Anda melaporkan",
      "Reporting": "Melaporkan",
      "Send Gifts": "Kirim Hadiah",
      "Request Date": "Tanggal Permintaan",
      "Ifsc Code": "Kode IFSC",
      "Account Name": "Nama Akun",
      "pay by": "bayar dengan",
      "Amount": "Jumlah",
      "Number of coin": "Jumlah koin",
      "Payout id": "ID Pembayaran"
    }

  }
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en', 
  fallbackLng: 'en', 
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
