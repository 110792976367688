import React, { useEffect } from 'react';
import { uid } from 'uid';
import KhaltiCheckout from "khalti-checkout-web";

const Khalti_Payment = ({ Amount }) => {

  const handlePayment = () => {
    let config = {
      publicKey: 'test_public_key_546eb6da05544d7d88961db04fdb9721', 
      productIdentity: uid(),
      productName: 'Product Name',
      productUrl: 'http://localhost:3000/', 
      amount: Amount * 100 ,
      eventHandler: {
        onSuccess: (payload) => {
          console.log('Payment Success', payload);
          alert('Payment Successful!');
        },
        onError: (error) => {
          console.error('Payment Failed', error);
          alert('Payment Failed!');
        },
        onDismiss: () => {
          console.log('Payment modal dismissed');
        }
      }
    };

    let checkout = new KhaltiCheckout(config);

    checkout.show({ amount: Amount * 100 });

  };

  useEffect(() => {
    handlePayment();
  },[])
  return (
    <div>
      <button onClick={handlePayment}>
        Pay with Khalti
      </button>
    </div>
  );
};

export default Khalti_Payment;
