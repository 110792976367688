import React, { createContext, useState } from 'react';

 const MyContext = createContext();

const MyProvider = ({ children }) => {

    // const basUrl = "http://15.207.11.52/happens/api/";
    const basUrl = "https://gomeet.cscodetech.cloud/api/";

    // const imageBaseURL = 'http://15.207.11.52/happens/';
    const imageBaseURL = "https://gomeet.cscodetech.cloud/";

    const [updateId , setUpdateId] = useState(0);

    const [name, setName] = useState();
    const [email , setEmail] = useState();
    const [password , setPassword] = useState();
    const [bio , setBio] = useState();
    const [number , setNumber] = useState();
    const [ccode , setCcode] = useState();
    const [birthdate , setBirthDate] = useState();
    const [gender , setGender] = useState();
    const [goal , setGoal] = useState();
    const [nearby , setNearby] = useState();
    const [hobbies , setHobbies] = useState();
    const [languages , setLanguages] = useState();
    const [religion , setReligion] = useState();
    const [preference , setPreference] = useState();
    const [latitude , setLatitude] = useState();
    const [longitude , setLongitude] = useState();
    const [uid , setUid] = useState();
    const [profileId , setProfileId] = useState();
    const [registerUid,setRegisterUid] = useState('')
    const [payClose , setPayClose] = useState();
    const [planId , setPlanId] = useState();
    const [transactionId , setTransactionId] = useState();
    const [toggleButton , setToggleButton] = useState(false);
    const [page , setPageName] = useState();
    const [amount , setAmount] = useState();
    const [buyCoin , setBuyCoin] = useState();
    const [purchaseId , setPurchaseId] = useState();
    const [walletCoin , setWalletCoin] = useState();
    const [demo , setDemo] = useState();
    const [error , setError] = useState();
    const [blockId , setBlockId] = useState();
    const [details , setDetails] = useState();

    const Value = {
        name,setName,
        email,setEmail,
        password,setPassword,
        bio,setBio,
        number,setNumber,
        ccode,setCcode,
        birthdate,setBirthDate,
        gender,setGender,
        goal,setGoal,
        nearby,setNearby,
        hobbies,setHobbies,
        languages,setLanguages,
        religion,setReligion,
        preference,setPreference,
        latitude,setLatitude,
        longitude,setLongitude,
        uid,setUid,
        profileId,setProfileId,
        registerUid,setRegisterUid,
        updateId,setUpdateId,
        payClose,setPayClose,
        planId,setPlanId,
        transactionId,setTransactionId,
        toggleButton,setToggleButton,
        page,setPageName,
        amount,setAmount,
        buyCoin,setBuyCoin,
        purchaseId,setPurchaseId,
        walletCoin,setWalletCoin,
        demo,setDemo,
        details,setDetails,
        blockId,setBlockId,
        error,setError,
        basUrl,imageBaseURL,
    }
    return (
        <MyContext.Provider value={Value}>
            {children}
        </MyContext.Provider>
    );
};

export { MyContext, MyProvider };
