import React, { useEffect, useState } from 'react';
import {
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
    Elements,
    useStripe,
    useElements
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { toast } from 'react-toastify';

// Helper function to create custom styles for Stripe elements
const createOptions = (fontSize, padding) => {
    return {
        style: {
            base: {
                fontSize,
                color: "#424770",
                letterSpacing: "0.025em",
                fontFamily: "Source Code Pro, monospace",
                "::placeholder": {
                    color: "#aab7c4"
                },
                padding
            },
            invalid: {
                color: "#9e2146"
            }
        }
    };
};

// Payment form component
const SplitForm = ({ fontSize, amount }) => {
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        // Create payment method using Stripe Elements
        const payload = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardNumberElement),
        });

        if (payload.error) {
            console.error("PaymentMethod Error : ", payload.error);
            toast(`💞 ${payload.error.message}`, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
                className: "toast-Style",
            });
        } else {
            console.log("Payment Done: ", payload);

            // Now send the payment method ID and the amount to the backend for finalizing the payment
            try {
                // Replace '/your-backend-endpoint' with your actual endpoint
                const response = await fetch('/api/charge', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        paymentMethodId: payload.paymentMethod.id,
                        amount: amount * 100, // Convert to cents
                    }),
                });

                const paymentResult = await response.json();

                if (paymentResult.error) {
                    toast(`💞 ${paymentResult.error.message}`, {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                        className: "toast-Style",
                    });
                } else {
                    toast('Payment successful!', {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                        className: "toast-Style",
                    });
                }
            } catch (error) {
                toast('Payment failed. Please try again later.', {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    className: "toast-Style",
                });
            }
        }
    };

    return (
        <form onSubmit={handleSubmit} className="DemoWrapper d-flex flex-column w-[100%]">
            <label>
                <span className='text-[18px] font-[500]'>Card number</span>
                <CardNumberElement className='border-[2px] py-[3px] px-[5px] rounded-[5px] mt-[5px]'
                    options={createOptions(fontSize)}
                />
            </label>
            <div className="flex justify-between items-center mt-[10px]">
                <label className='w-[40%]'>
                    <span className='text-[18px] font-[500]' >Expiration date</span>
                    <CardExpiryElement className='border-[2px] focus-within:border-[rgba(152,14,255,255)] py-[3px] px-[5px] rounded-[5px] mt-[5px]'
                        options={createOptions(fontSize)}
                    />
                </label>
                <label className='w-[40%]'>
                    <span className='text-[18px] font-[500]' >CVC</span>
                    <CardCvcElement className='border-[2px] py-[3px] px-[5px] rounded-[5px] mt-[5px]'
                        options={createOptions(fontSize)}
                    />
                </label>
            </div>
            <button type="submit" className='mt-[20px] bg-[rgba(152,14,255,255)] text-white text-[18px] py-[5px] rounded-[5px]'>
                Pay {amount ? `$${amount}` : ""}
            </button>
        </form>
    );
};

// Load Stripe API key
const stripePromise = loadStripe("pk_test_6pRNASCoBOKtIshFeQd4XMUh");

// Main component that contains the Stripe form
export const StripePayment = ({ Amount }) => {
    const [elementFontSize, setElementFontSize] = useState(() =>
        window.innerWidth < 450 ? "14px" : "18px"
    );

    useEffect(() => {
        const handleResize = () => {
            setElementFontSize(window.innerWidth < 450 ? "14px" : "18px");
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <div className="Checkout w-[100%] text-start">
            <Elements stripe={stripePromise}>
                <SplitForm fontSize={elementFontSize} amount={Amount} />
            </Elements>
        </div>
    );
};
