import React, { useContext, useEffect, useRef, useState } from "react";
import Dott from "../Icon/more-vertical.svg";
import { HiGift } from "react-icons/hi2";
import HidePassword from "../Icon/eye-slash.svg";
import flag from "../Icon/flag-triangle.svg";
import plus from "../Icon/plus.svg";
import Bell from "../Icon/bell-slash.svg";
import BlockIcon from '../Icon/block.gif'
import Setting from "../Icon/settings.svg";
import { useNavigate } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";
import { FaHeart } from "react-icons/fa";
import { IoChatbubbleEllipsesSharp } from "react-icons/io5";
import CloseIcon from "../Icon/times.svg";
import Star from "../Icon/star.png";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { toast } from "react-toastify";
import axios from "axios";
import { MyContext } from "../Context/MyProvider ";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";  
import Slider from "react-slick";
import { useTranslation } from "react-i18next";

const ReportComment = ["Harassment", "inappropriate Content", "Violation of Terms", "Threats", "Castfishing", "Unwanted Advances", "Unsolicited Explicit Content", "Privacy Concerns", "Scam or Spam", "Unwanted Advances"]

const Detail = () => {

    const { t } = useTranslation();


  const { basUrl, imageBaseURL } = useContext(MyContext);

  const [options, setOption] = useState(false);
  const [report, setReport] = useState(false);
  const [dot, setDot] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);
  const [giftid, setGiftId] = useState([]);
  const [gifterror, setGiftError] = useState(0);
  const [api, setApi] = useState([]);
  const [imageData, setImageData] = useState([]);
  const [isverify, setIsVerify] = useState();
  const [comment, setComment] = useState();
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [giftList, setGiftList] = useState([]);
  const [yourCoin, setYourCoin] = useState();
  const [totalPrice, setTotalPrice] = useState(0);
  const [giftImg, setGiftImg] = useState([]);
  const [giftReceiverId, setGiftreceiverId] = useState();

  const navigate = useNavigate();


  const toggleBottomSheet = (e) => {
    if (e.target.id === 'BlockSection') {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };

  const SendReportHamdler = (id) => {
    if (dot) {
      const UserData = localStorage.getItem("Register_User");
      const UserId = JSON.parse(UserData);

      axios.post(`${basUrl}report.php`,
        {
          uid: UserId.id,
          reporter_id: id,
          comment: comment
        }
      )
        .then((res) => {
          // console.log("ReportUser : " , res);
          if (res.data.Result == "true") {
            toast("💞 Report Send successfully!!", {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme: "dark",
              className: "toast-Style",
            });
            setReport(false);
          }
        })
    } else {

      toast("💞 Something Went Wrong!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "dark",
        className: "toast-Style",
      });
    }
  }

  const ReportToggle = (e) => {
    if (e == 'ReportSection') {
      setReport(false);
    } else {
      setReport(true);
    }
  }

  const BlockHandler = () => {
    const Local = localStorage.getItem("Register_User");
    if (Local) {
      const UserId = JSON.parse(Local);

      axios.post(`${basUrl}profile_block.php`, { uid: UserId.id, profile_id: api.profile_id })
        .then((res) => {
          // console.log("BlockUser : ", res);
          if (res.data.Result == "true") {
            const Id = sessionStorage.getItem("Icon-Color");
            if (Id == "Explore") {
              navigate("/favorites");
            } else {
              navigate("/");
            }
            toast(`💞 Profile Block Successfully!!`, {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme: "dark",
              className: "toast-Style",
            });
            setIsVisible(false);
          } else {
            toast(`💞 ${res.data.ResponseMsg}`, {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme: "dark",
              className: "toast-Style",
            });
            setIsVisible(false);
          }
        })
    }
  }

  const toggleBottomSheet2 = (e) => {
    if (e == 'GiftSection') {
      setIsVisible2(false);
    } else {
      setIsVisible2(true);
    }
  };

  const GiftHandler = (id, price, img) => {
    const GiftCoin = parseInt(price)
    if (giftid.includes(id)) {
      setGiftId(giftid.filter((el) => el !== id));
      setGiftImg(giftImg.filter((el) => el !== img));
      setTotalPrice(totalPrice - GiftCoin);
      setGiftError(gifterror - 1);
    } else {
      if (totalPrice + GiftCoin > yourCoin) {
        toast("💞 insufficient coins in wallet", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "dark",
          className: "toast-Style",
        });
      } else {
        setGiftId([...giftid, id]);
        setTotalPrice(totalPrice + GiftCoin);
        setGiftError(gifterror + 1);
        setGiftImg([...giftImg, img]);
      }
    }
  };

  const SednHandler = () => {
    if (!gifterror) {
      toast("💞 Please Select Gift", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "dark",
        className: "toast-Style",
      });
    } else {
      GiftSendHandler();
    }
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const lat = String(position.coords.latitude);
        const long = String(position.coords.longitude);
        setLatitude(lat);
        setLongitude(long);
        DetailsHandler(lat, long);
      },
      (error) => {
        if (error.code === error.PERMISSION_DENIED) {
        }
      })
  }, [latitude, longitude])

  const DetailsHandler = (lat, long) => {
    const Local = localStorage.getItem("Register_User");
    if (Local) {
      const UserData = JSON.parse(Local);
      const Id = localStorage.getItem("DetailsId");
      axios
        .post(`${basUrl}profile_info.php`, {
          uid: UserData.id,
          profile_id: Id,
          lats: lat,
          longs: long,
        })
        .then((res) => {
          // console.log(res);
          setApi(res?.data.profileinfo);
          setImageData(res?.data.profileinfo?.profile_images)
          setIsVerify(res?.data.profileinfo?.is_verify)
          setGiftreceiverId(res?.data.profileinfo?.profile_id);
        });
    }
  }

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000
  };

  const NavigateHandler = () => {
    const Id = sessionStorage.getItem("Icon-Color");
    if (Id == "Explore") {
      navigate("/favorites");
      localStorage.setItem("DetailsId", "");
    } else {
      navigate("/");
      localStorage.setItem("DetailsId", "");
    }
  }

  const ProfileLikeHandler = (id) => {
    const Local = localStorage.getItem("Register_User");
    if (id && Local) {

      const UserData = JSON.parse(Local);

      axios.post(`${basUrl}like_dislike.php`,
        {
          uid: UserData.id,
          profile_id: id,
          action: "LIKE"
        })
        .then((res) => {
          // console.log("Like : ", res);
          if (res.data.Result == "true") {
            toast(`💞 Like Successfully!!! `, {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme: "dark",
              className: "toast-Style",
            });
          }
        })
    }
  }

  const ProfileUnLikeHandler = (id) => {
    const Local = localStorage.getItem("Register_User");
    if (id && Local) {

      const UserData = JSON.parse(Local);

      axios.post(`${basUrl}like_dislike.php`,
        {
          uid: UserData.id,
          profile_id: id,
          action: "UNLIKE"
        })

        .then((res) => {
          // console.log("Like : ", res);
          if (res.data.Result == "true") {
            NavigateHandler();
            toast(`💞 UnLike Successfully!!! `, {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme: "dark",
              className: "toast-Style",
            });
          }
        })

    }
  }


  const CoinHandler = () => {
    const localData = localStorage.getItem("Register_User");

    if (localData) {
      const userData = JSON.parse(localData);
      axios.post(`${basUrl}coin_report.php`, { uid: userData.id })
        .then((res) => {
          // console.log("Coin Package : ", res);
          setYourCoin(res.data.coin);
        })
    }
  }

  useEffect(() => {
    axios.post(`${basUrl}gift_list.php`)
      .then((res) => {
        // console.log("Gift LIst : ", res);
        setGiftList(res.data.giftlist);
      })
    CoinHandler();
  }, [])

  const GiftSendHandler = () => {
    const localData = localStorage.getItem("Register_User");

    if (localData) {
      const userData = JSON.parse(localData);

      const img = giftImg.join(';');
      axios.post(`${basUrl}giftbuy.php`,
        {
          sender_id: userData.id,
          coin: totalPrice,
          receiver_id: giftReceiverId,
          gift_img: img
        }
      )
        .then((res) => {
          console.log("Gift : ", res);
          if (res.data.Result == "true") {
            setGiftId("");
            setGiftError("");
            setTotalPrice("");
            CoinHandler();
            toast("💞 Gift Send Successfully!!", {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme: "dark",
              className: "toast-Style",
            });
            setIsVisible2(false);
          }
        })
    }
  }
  return (
    <div className="outline-none">
      {/* <!-- Main Content Start --> */}
      <div
        onClick={() => options && setOption(!options)}
        className="content-body bg-[#e5e5e5] relative"
      >
        <div className="container mw-lg-100 pt-2 pb-4 px-sm-4 px-3">
          <div className="">
            <button onClick={NavigateHandler} className="bg-[white] w-[30px] max-_430_:-mt-[0px] mb-[10px] h-[30px] rounded-full p-[6px] flex justify-center items-center z-[555]">
              <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.7501 8.00002C19.7501 8.41402 19.4141 8.75002 19.0001 8.75002H2.81115L8.53112 14.47C8.82412 14.763 8.82412 15.238 8.53112 15.531C8.38512 15.677 8.19309 15.751 8.00109 15.751C7.80909 15.751 7.61706 15.678 7.47106 15.531L0.471062 8.53103C0.402063 8.46203 0.347197 8.37913 0.309197 8.28713C0.233197 8.10413 0.233197 7.89713 0.309197 7.71413C0.347197 7.62213 0.402063 7.53899 0.471062 7.46999L7.47106 0.469994C7.76406 0.176994 8.2391 0.176994 8.5321 0.469994C8.8251 0.762994 8.8251 1.23803 8.5321 1.53103L2.81213 7.251H19.0001C19.4141 7.25 19.7501 7.58602 19.7501 8.00002Z" fill="#25314C" />
              </svg>
            </button>
          </div>
          <div className="card card-rounded-2">
            <div className="card-body p-lg-4 p-sm-3">
              <div className="row p-lg-3 p-sm-2 items-center">
                <div className="col-xxl-5">
                  <div className="user-images-slider">
                    <div className="swiper-container images-slider overflow-hidden relative">
                      <button className="absolute top-[15px] hidden max-_430_:block right-[20px] z-[555]" onClick={() => setOption(!options)}>
                        <svg width="6" height="20" viewBox="0 0 4 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path className="fill-white" d="M2.02002 3.5C1.19102 3.5 0.515015 2.829 0.515015 2C0.515015 1.171 1.18201 0.5 2.01001 0.5H2.02002C2.84902 0.5 3.52002 1.171 3.52002 2C3.52002 2.829 2.84902 3.5 2.02002 3.5ZM3.52002 10C3.52002 9.171 2.84902 8.5 2.02002 8.5H2.01001C1.18201 8.5 0.515015 9.171 0.515015 10C0.515015 10.829 1.19102 11.5 2.02002 11.5C2.84902 11.5 3.52002 10.829 3.52002 10ZM3.52002 18C3.52002 17.171 2.84902 16.5 2.02002 16.5H2.01001C1.18201 16.5 0.515015 17.171 0.515015 18C0.515015 18.829 1.19102 19.5 2.02002 19.5C2.84902 19.5 3.52002 18.829 3.52002 18Z" fill="#25314C" />
                        </svg>
                      </button>
                      {options && (
                        <div className="bg-gray-200 m-0 px-[5px] hidden max-_430_:block rounded-[5px] absolute top-[45px] right-[18px] z-[888]  ">
                          <button onClick={toggleBottomSheet} className="py-[5px] px-[10px] flex items-center gap-[10px] font-[600]">
                            <img src={HidePassword} style={{height: "100%"}} alt="" /> {t('Block')}
                          </button>
                          <div className="border-[1px] border-gray-300 "></div>
                          <button onClick={ReportToggle} className="py-[5px] px-[10px] flex items-center gap-[10px] font-[600]">
                            <img src={flag} alt="" style={{height: "100%"}} />
                            {t('Report')}
                          </button>
                        </div>
                      )}
                      {imageData?.length > 1
                        ? <Slider {...settings}>
                          {
                            imageData.map((el, i) => {
                              return <div>
                                <img className="rounded-[1rem] relative" src={`${imageBaseURL}${el}`} alt="" />
                              </div>
                            })
                          }
                        </Slider>
                        : imageData?.map((el) => {
                          return <div>
                            <img className="rounded-[1rem]" src={`${imageBaseURL}${el}`} alt="" />
                          </div>
                        })
                      }
                      <div className="absolute bottom-10 max-_1445_:bottom-16 max-_430_:hidden flex items-center justify-center w-[99%] gap-[10px]">
                        <div className="image-action-icon items-center">
                          <button onClick={() => ProfileUnLikeHandler(api.profile_id)} className="action-btn avatar avatar-lg avatar-rounded z-1 bg-white" >
                            <RxCross2 className="w-[50px] text-black" />
                          </button>
                          <a className="action-btn avatar avatar-lg avatar-rounded z-1 bg-white">
                            <IoChatbubbleEllipsesSharp className="text-black" />
                          </a>
                          <button onClick={() => ProfileLikeHandler(api.profile_id)} className="action-btn avatar avatar-lg avatar-rounded z-1 bg-red-500">
                            <FaHeart className="text-white" />
                          </button>
                          <button className="action-btn avatar avatar-lg avatar-rounded z-1 bg-white" onClick={(e) => { e.stopPropagation(); toggleBottomSheet2(); }} >
                            <HiGift className="text-black" />
                          </button>
                        </div>
                      </div>
                      <div className="hidden max-_430_:block z-[999] relative">
                        <div className="fixed bottom-5 left-0 flex items-center justify-center w-[99%] gap-[10px]">
                          <div className="flex gap-[1rem] rounded-full px-[5px] items-center bg-black py-[5px]">
                            <button onClick={() => ProfileUnLikeHandler(api.profile_id)} className="action-btn avatar avatar-lg rounded-full z-1 bg-white" >
                              <RxCross2 className="w-[50px] text-black" />
                            </button>
                            <a className="action-btn avatar avatar-lg rounded-full z-1 bg-white">
                              <IoChatbubbleEllipsesSharp className="text-black" />
                            </a>
                            <button onClick={() => ProfileLikeHandler(api.profile_id)} className="action-btn avatar avatar-lg rounded-full z-1 bg-red-500">
                              <FaHeart className="text-white" />
                            </button>
                            <button className="action-btn avatar avatar-lg rounded-full z-1 bg-white" onClick={(e) => { e.stopPropagation(); toggleBottomSheet2(); }} >
                              <HiGift className="text-black" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-7">
                  <div className="user-profile">
                    <div className="border-bottom d-sm-flex align-items-cneter px-sm-2 py-4 px-1">
                      <div className="d-flex items-center flex-fill">
                        <h3 className="mb-0">{api?.profile_name} ({api?.profile_age})</h3>
                        {isverify == "0"
                          ? <div className="tooltip">
                            <svg width="20" className="ms-[10px]" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path className="fill-yellow-500" d="M10 21.7501C9.92 21.7501 9.83994 21.7371 9.76294 21.7111C8.17394 21.1811 0.25 18.1221 0.25 9.88805V4.00012C0.25 3.64312 0.502027 3.33401 0.853027 3.26501C5.73303 2.28901 7.46305 1.43111 9.65405 0.344109C9.86605 0.239109 10.146 0.225193 10.356 0.330193C12.517 1.41919 14.2239 2.28001 19.1479 3.26501C19.4989 3.33501 19.751 3.64312 19.751 4.00012V9.88903C19.751 18.123 11.827 21.182 10.238 21.712C10.16 21.737 10.08 21.7501 10 21.7501ZM1.75 4.61218V9.88805C1.75 16.7531 8.168 19.5482 10 20.2052C11.832 19.5482 18.25 16.7521 18.25 9.88805V4.61218C13.829 3.68718 11.9699 2.81901 10.0149 1.83801C7.93789 2.86501 6.135 3.69618 1.75 4.61218ZM10.71 11.6161C10.718 11.5961 10.798 11.4151 11.344 11.0521C12.328 10.3981 12.769 9.45912 12.584 8.40612C12.397 7.34712 11.528 6.47811 10.47 6.29211C9.69197 6.15611 8.90799 6.36511 8.31299 6.86511C7.71799 7.36511 7.375 8.09809 7.375 8.87609C7.375 9.29009 7.711 9.62609 8.125 9.62609C8.539 9.62609 8.875 9.29009 8.875 8.87609C8.875 8.54309 9.0211 8.22903 9.2771 8.01403C9.5361 7.79703 9.86996 7.70814 10.21 7.77013C10.652 7.84813 11.0289 8.22513 11.1069 8.66613C11.1569 8.95213 11.15 9.38007 10.512 9.80407C10.083 10.0901 9.49109 10.5391 9.28809 11.1371C9.15709 11.5241 9.36598 11.9332 9.75098 12.0712C9.83198 12.1002 9.91409 12.1132 9.99609 12.1132C10.3051 12.1122 10.597 11.9181 10.71 11.6161ZM11.02 14.0001C11.02 13.4481 10.573 13.0001 10.02 13.0001H10.01C9.45801 13.0001 9.01489 13.4481 9.01489 14.0001C9.01489 14.5521 9.46802 15.0001 10.02 15.0001C10.572 15.0001 11.02 14.5521 11.02 14.0001Z" fill="#25314C" />
                            </svg>
                            <span className="tooltiptext w-[80px] -bottom-[35px] left-[8px]">Un Verified</span>
                          </div>
                          : isverify == "2"
                          && <div className="tooltip">
                            <svg width="20" className="ms-[10px]" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path className="fill-green-500 " d="M10 21.7501C9.92 21.7501 9.83994 21.7371 9.76294 21.7111C8.17394 21.1811 0.25 18.1221 0.25 9.88805V4.00012C0.25 3.64312 0.502027 3.33401 0.853027 3.26501C5.73303 2.28901 7.46305 1.43111 9.65405 0.344109C9.86505 0.239109 10.145 0.225193 10.356 0.330193C12.517 1.41919 14.2239 2.28001 19.1479 3.26501C19.4989 3.33501 19.751 3.64312 19.751 4.00012V9.88903C19.751 18.123 11.827 21.182 10.238 21.712C10.16 21.737 10.08 21.7501 10 21.7501ZM1.75 4.61218V9.88805C1.75 16.7531 8.168 19.5482 10 20.2052C11.832 19.5482 18.25 16.7521 18.25 9.88805V4.61218C13.829 3.68718 11.9699 2.81901 10.0149 1.83801C7.93789 2.86501 6.135 3.69618 1.75 4.61218ZM9.54199 13.5301L13.542 9.53014C13.835 9.23714 13.835 8.76211 13.542 8.46911C13.249 8.17611 12.774 8.17611 12.481 8.46911L9.01099 11.9391L7.54102 10.4691C7.24802 10.1761 6.77298 10.1761 6.47998 10.4691C6.18698 10.7621 6.18698 11.2371 6.47998 11.5301L8.47998 13.5301C8.62598 13.6761 8.81801 13.7501 9.01001 13.7501C9.20201 13.7501 9.39599 13.6771 9.54199 13.5301Z" fill="#25314C" />
                            </svg>
                            <span className="tooltiptext w-[65px] -bottom-[35px] left-[18px]">Is Verify</span>
                          </div>}
                      </div>
                      <div className="flex items-center gap-[5px] max-_580_:mt-[15px] z-[111]">
                        <svg
                          className="svg-gradient"
                          width="15"
                          height="18"
                          viewBox="0 0 15 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path style={{ fill: "rgba(152,14,255,255)" }}
                            d="M12.201 1.72304C11.4404 1.10344 10.5568 0.65271 9.60864 0.400784C8.66052 0.148858 7.66965 0.101496 6.70182 0.261844C5.734 0.422192 4.81135 0.786581 3.99514 1.33082C3.17892 1.87506 2.48782 2.58669 1.9677 3.41849C1.44758 4.25028 1.11034 5.18319 0.978386 6.1553C0.84643 7.1274 0.922767 8.11646 1.20233 9.0568C1.4819 9.99714 1.95829 10.8673 2.59989 11.6094C3.24149 12.3515 4.03361 12.9487 4.92367 13.3612L6.51275 16.5394C6.63596 16.7857 6.82531 16.9928 7.0596 17.1376C7.29388 17.2824 7.56385 17.359 7.83925 17.359C8.11465 17.359 8.38462 17.2824 8.6189 17.1376C8.85318 16.9928 9.04253 16.7857 9.16574 16.5394L10.7674 13.3622C11.8299 12.8664 12.7489 12.1086 13.4381 11.1601C14.1274 10.2115 14.5642 9.10335 14.7075 7.93965C14.8509 6.77594 14.696 5.59489 14.2576 4.50746C13.8191 3.42002 13.1115 2.46188 12.201 1.72304ZM7.83925 10.0614C7.25267 10.0614 6.67927 9.88746 6.19155 9.56157C5.70383 9.23569 5.3237 8.7725 5.09923 8.23057C4.87475 7.68865 4.81602 7.09233 4.93046 6.51703C5.04489 5.94172 5.32735 5.41327 5.74213 4.9985C6.1569 4.58373 6.68535 4.30126 7.26065 4.18683C7.83596 4.07239 8.43228 4.13113 8.9742 4.3556C9.51613 4.58007 9.97932 4.9602 10.3052 5.44792C10.6311 5.93564 10.805 6.50904 10.805 7.09562C10.805 7.88219 10.4925 8.63653 9.93635 9.19272C9.38016 9.74891 8.62582 10.0614 7.83925 10.0614Z"
                            fill="url(#paint0_linear_72_680)"
                          ></path>
                        </svg>
                        <div className="flex items-center gap-[10px] relative">
                          <span className="fs-16 text-gray">{api?.profile_distance} from you</span>
                          <button onClick={() => setOption(!options)}>
                            <img
                              src={Dott}
                              className="w-[20px] h-[20px] max-_430_:hidden"
                              alt=""
                            />
                          </button>
                          {options && (
                            <div className="bg-gray-200 m-0 px-[5px] max-_430_:hidden rounded-[5px] absolute top-[35px] right-[10px]">
                              <button onClick={toggleBottomSheet} className="py-[5px] px-[10px] flex items-center gap-[10px] font-[600]">
                                <img src={HidePassword} alt="" /> {t("Block")}
                              </button>
                              <div className="border-[1px] border-gray-300 "></div>
                              <button onClick={ReportToggle} className="py-[5px] px-[10px] flex items-center gap-[10px] font-[600]">
                                <img src={flag} alt="" />
                                {t("Report")}
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="px-sm-2 py-4 px-1 border-bottom">
                      <h6 className="fw-semi-bold mb-2">{t("Bio")} :  <span className="fs-15 font-[400]">{api?.profile_bio == "undefined" ? "" : api?.profile_bio}</span></h6>
                    </div>
                    <div className="border-bottom px-sm-2 py-4 px-1 user-interests">
                      <h6 className="fw-semi-bold mb-2">{t('Interests')}:</h6>
                      <div className="d-flex flex-wrap list-group-items round-style">
                        {api?.interest_list?.map((item) => {
                          return <div className="list-item d-flex align-items-center justify-content-center py-2 px-3 fw-medium">
                            <span>{item?.title}</span>
                            <img
                              src={imageBaseURL + item?.img}
                              alt="english"
                              className="rounded-circle lang-tag-icon"
                            />
                          </div>
                        })}
                      </div>
                    </div>
                    <div className="border-bottom px-sm-2 py-4 px-1">
                      <h6 className="fw-semi-bold mb-2">{t("Languages")}:</h6>
                      <div className="d-flex flex-wrap list-group-items round-style">
                        {
                          api?.language_list?.map((item) => {
                            return <div className="list-item d-flex align-items-center justify-content-center py-2 px-3 fw-medium">
                              <img
                                src={imageBaseURL + item?.img}
                                alt="english"
                                className="rounded-circle lang-tag-icon"
                              />
                              <span>{item?.title}</span>
                            </div>
                          })
                        }

                      </div>
                    </div>
                    <div className="border-bottom px-sm-2 py-4 px-1">
                      <h6 className="fw-semi-bold mb-2">{t("Relationship Goals")}:</h6>
                      <div className="d-flex flex-wrap list-group-items round-style">
                        <div className="list-item d-flex align-items-center justify-content-center py-2 px-3 fw-medium">
                          {api?.relation_title}
                        </div>
                      </div>
                    </div>
                    <div className=" px-sm-2 py-4 px-1">
                      <h6 className="fw-semi-bold mb-2">{t('Religion')}:</h6>
                      <div className="d-flex flex-wrap list-group-items round-style">
                        <div className="list-item d-flex align-items-center justify-content-center py-2 px-3 fw-medium">
                          {api?.religion_title}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
      {/* <!-- Main Content End --> */}

      {/* <----------- Block Section ---------------> */}
      {isVisible && <div onClick={toggleBottomSheet} id="BlockSection" className=" px-[15px] py-[15px] w-full h-full fixed top-0 left-0 flex items-center justify-center bg-black bg-opacity-50 z-[999]">
        <div onClick={(e) => e.stopPropagation()} className="w-[20%] max-_430_:w-[100%] max-_768_:w-[90%] max-_1030_:w-[50%] max-_1500_:w-[40%] bg-white rounded-[15px] p-[15px]">
          <div className="flex flex-col justify-center items-center text-center">
            <img src={BlockIcon} alt="" className="w-[30%]" />
            <h4 className="m-0">{t('Blocking')} {api?.profile_name}</h4>
            <p className="mt-[20px] leading-[18px] text-[15px] pb-[15px]">
              {t('Please tell us why you are blocking')} <br /> {t("Acchcuu. Don't worry we won't tell them..")}
            </p>
            <div className="flex items-center border-t-[2px] pt-[10px] gap-[15px]">
              <div className="">
                <div className="flex items-center gap-[15px]">
                  <img src={plus} alt="" />
                  <p className="text-start leading-[18px] font-[500]">
                    {t("They will not be able to find your profile and send you messages.")}
                  </p>
                </div>
                <div className="flex items-center gap-[10px] mt-[15px]">
                  <img src={Bell} alt="" />
                  <p className="text-start leading-[18px] font-[500]">
                    {t('They will not be notified if you block them.')}
                  </p>
                </div>
                <div className="flex items-center gap-[10px] mt-[15px]">
                  <img src={Setting} alt="" />
                  <p className="text-start leading-[18px] font-[500]">
                    {t('You can unblock them anytime in Settings.')}
                  </p>
                </div>
                <div className="flex justify-center gap-[20px] mt-[25px]">
                  <button onClick={toggleBottomSheet} id="BlockSection" className="text-[16px] text-[rgba(152,14,255,255)] h-[40px] max-h-[40px] border-[2px] border-[rgba(152,14,255,255)] rounded-full px-[25px]">
                    {t('Cancel')}
                  </button>
                  <button onClick={BlockHandler} className="text-[16px
                    ] text-white border-[2px] bg-[rgba(152,14,255,255)] rounded-full px-[25px] py-[8px]">
                    {t('Yes, Block')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>}

      {/* <----------- Report Section ---------------> */}
      {report && <div onClick={() => ReportToggle('ReportSection')} className="px-[15px] py-[15px] w-full h-full fixed top-0 left-0 flex items-center justify-center bg-black bg-opacity-50 z-[999]">
        <div onClick={(e) => e.stopPropagation()} className="w-[20%] max-_430_:w-[100%] max-_768_:w-[90%] max-_1030_:w-[50%] max-_1500_:w-[40%] bg-white rounded-[15px] p-[15px]">
          <div className="flex flex-col justify-center items-center text-center">
            <div className="">
              <h3 className="m-0 font-[500]">{t('Reporting')} {api?.profile_name}</h3>
              <p className="leading-[18px] mt-[10px] font-[400] text-[16px]">{t("Pelease tell us why you are reporting")} <br /> {api?.profile_name}. {t("Don't worry we won't tell them")}</p>
              <h6 className=" text-start mt-[10px] font-[500] text-[18px]">{t("Why did you rport this user?")}</h6>
              <div className="ps-[10px]">
                {
                  ReportComment.map((item, index) => {
                    return <button onClick={() => { setDot(index); setComment(item); }} className="flex items-center gap-[35px] my-[15px]">
                      <div className="dot">
                        <span style={{ background: dot === index && "#980EFF" }} className="w-[100%] h-[100%] block rounded-full duration-[0.9s]"></span>
                      </div>
                      <h6 className="m-0 font-[400]">{item}</h6>
                    </button>
                  })
                }
                <button onClick={() => SendReportHamdler(api.profile_id)} className="w-[100%] bg-[#980EFF] text-white rounded-full text-[18px] py-[8px] mt  -[20px]">{t("Continue")}</button>
              </div>
            </div>
          </div>
        </div>
      </div>}

      {/* <----------- Gift Section ---------------> */}
      {isVisible2 && (
        <div onClick={() => toggleBottomSheet2("GiftSection")} className="bottom-sheet z-[999]">
          <div onClick={(e) => e.stopPropagation()} className="bottom-sheet-content">
            <div className="flex items-center justify-between">
              <h1 className="text-[18px] m-0 font-[600]">{t('Send Gifts')}</h1>
              <div className="flex items-center gap-[10px]">
                <img src={Star} alt="" className="w-[25px] h-[25px]" />
                <span className="text-[18px]">{yourCoin}</span>
                <img
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleBottomSheet2("GiftSection");
                  }}
                  src={CloseIcon}
                  alt=""
                  className="w-[15px] h-[15px] ms-[15px] cursor-pointer"
                />
              </div>
            </div>

            <div className="flex flex-wrap items-center gap-[20px] mx-auto mt-3 max-_430_:gap-[10px]">
              {giftList.map((el, i) => (
                <button
                  key={el.Id}
                  onClick={() => GiftHandler(el.id, el.price, el.img)}
                  style={{
                    borderColor: giftid.includes(el.id) ? "rgba(152,14,255,255)" : "#D1D5DB",
                  }}
                  className="w-[20%] max-_430_:w-[calc(25%-10px)] border-[2px] flex justify-center py-[5px] rounded-[10px]"
                >
                  <div className="relative">
                    <img
                      src={imageBaseURL + el.img}
                      alt=""
                      className="w-[45px] h-[45px] mx-auto max-_380_:w-[40px] max-_380_:h-[40px] max-_330_:w-[30px] max-_330_:h-[30px]"
                    />
                    <div className="flex items-center justify-center gap-[5px] mt-2">
                      <img
                        src={Star}
                        alt=""
                        className={`w-[15px] h-[15px]${el.price == 0 && "hidden text-center"}`}
                      />
                      <span className="text-[14px] font-[500]"> {el.price == 0 ? "Free" : el.price}</span>
                    </div>
                    <IoIosCheckmarkCircle
                      style={{
                        display: giftid.includes(el.id) ? "block" : "none",
                      }}
                      className="absolute -top-[15px] -right-[30px] max-_380_:-right-[20px] max-_330_:-right-[10px] w-[25px] h-[25px] text-[rgba(152,14,255,255)] bg-white rounded-full"
                    />
                  </div>
                </button>
              ))}
            </div>
            <button
              onClick={SednHandler}
              className="font-bold text-[18px] rounded-[10px] mt-[20px] text-white py-[10px] w-[100%] bg-[rgba(152,14,255,255)]"
            >
              Send
            </button>
          </div>
        </div>

      )}
    </div>

  );
};

export default Detail;
