import React, { useContext, useEffect, useState } from "react";
import { RiHeart3Fill } from "react-icons/ri";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { RxCross2 } from "react-icons/rx";
import { MyContext } from "../Context/MyProvider ";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Favorites = () => {

  const { t } = useTranslation();

  const { basUrl, imageBaseURL } = useContext(MyContext);
  const naviget = useNavigate();

  const [showPage, setShowPage] = useState();
  const [favourite, setFavourite] = useState([]);
  const [newMatch, setNewMatch] = useState([]);
  const [likeMe, setLikeMe] = useState([]);
  const [passed, setPassed] = useState([]);

  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();

  useEffect(() => {
  }, [showPage])

  const ShowPageHandler = (el) => {
    sessionStorage.setItem("Explore", el)
    setShowPage(el);
  }

  useEffect(() => {
    if (sessionStorage.getItem("Explore")) {
      setShowPage(sessionStorage.getItem("Explore"));
    } else {
      setShowPage("Match");
    }
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const lat = String(position.coords.latitude);
        const long = String(position.coords.longitude);

        setLatitude(lat);
        setLongitude(long);

        FavouritrHandler(lat, long);
        NewMatchHandler(lat, long);
        LikeMeHandler(lat, long);
        PassedHandler(lat, long);
      },
      (error) => {
        if (error.code === error.PERMISSION_DENIED) {
          console.error("Permission denied for geolocation.");
        }
      }
    );
  }, []);

  // <<------- Api Cali Start Section ------->>

  const FavouritrHandler = (lat, long) => {
    const localData = localStorage.getItem("Register_User");
    const userData = JSON.parse(localData);

    axios.post(`${basUrl}favourite.php`,
      {
        uid: userData.id,
        lats: lat,
        longs: long
      }
    )
      .then((res) => {
        // console.log("Favourite User", res);
        if (res.data.Result == "true") {
          setFavourite(res.data.favlist);
        }
      })
  }

  const NewMatchHandler = (lat, long) => {
    const localData = localStorage.getItem("Register_User");
    const userData = JSON.parse(localData);

    axios.post(`${basUrl}new_match.php`, {
      uid: userData.id,
      lats: lat,
      longs: long
    })
      .then((res) => {
        // console.log("New Match User", res);
        if (res.data.Result === "true") {
          setNewMatch(res.data.profilelist);
        }
      })
      .catch((error) => {
        console.error("Error fetching new match:", error);
      });
  }

  const LikeMeHandler = (lat, long) => {
    const localData = localStorage.getItem("Register_User");
    const userData = JSON.parse(localData);

    axios.post(`${basUrl}like_me.php`,
      {
        uid: userData.id,
        lats: lat,
        longs: long
      }
    )
      .then((res) => {
        // console.log("Favourite User", res);
        if (res.data.Result == "true") {
          setLikeMe(res.data.likemelist);
        }
      })
  }

  const PassedHandler = (lat, long) => {
    const localData = localStorage.getItem("Register_User");
    const userData = JSON.parse(localData);

    axios.post(`${basUrl}passed.php`,
      {
        uid: userData.id,
        lats: lat,
        longs: long
      }
    )
      .then((res) => {
        // console.log("Favourite User", res);
        if (res.data.Result == "true") {
          setPassed(res.data.passedlist);
        }
      })
  }

  const DetailsHandler = (profile_id , name) => {
    const Title = name.replace(/\s+/g, '_');
    const FinalText = Title.toLowerCase();
    naviget(`/detail/${FinalText}`);
    localStorage.setItem("DetailsId", profile_id);
  }

  const UserLikeHandler = (Id) => {
    const localData = localStorage.getItem("Register_User");
    const userData = JSON.parse(localData);

    axios.post(`${basUrl}like_dislike.php`, { uid: userData.id, profile_id: Id, action: "LIKE" })
      .then((res) => {
        // console.log(res);
        LikeMeHandler(latitude, longitude);
        FavouritrHandler(latitude, longitude);
      })
  }

  const UserUnLikeHandler = (Id) => {
    const localData = localStorage.getItem("Register_User");
    const userData = JSON.parse(localData);

    axios.post(`${basUrl}like_dislike.php`, { uid: userData.id, profile_id: Id, action: "UNLIKE" })
      .then((res) => {
        // console.log(res);
        LikeMeHandler(latitude, longitude);
        PassedHandler(latitude, longitude);
      })
  }

  return (
    <div>
      <div className="content-body bg-[#e5e5e5] ">
        <div className="container-fluid py-4 px-sm-4 px-3">
          <div className="row">
            <div className="col-xl-12 max-_1030_:fixed top-[74px] max-_1030_:z-[555] bg-[#e5e5e5] ">
              <div className="card card-rounded my-4">
                <div className="card-body card-py-1 scroll-container2">
                  <div className="flex items-center gap-[20px] w-[100%]">

                    <button
                      onClick={() => ShowPageHandler("Match")}
                      style={{
                        color: showPage == "Match" ? "white" : "rgba(152,14,255,255)",
                        background: showPage == "Match"
                          ? "rgba(152,14,255,255)"
                          : "#970eff31",
                      }}
                      className="inline-block py-[7px] px-[10px] rounded-[10px]"
                    >
                      <div className="flex justify-around items-center gap-[10px]">
                        <RiHeart3Fill className="min-w-[25px] min-h-[25px]" />
                        <h6 className="m-0 inline-block text-[20px] font-[400] max-_430_:text-[16px]">
                          {t('New Match')}
                        </h6>
                      </div>
                    </button>

                    <button
                      onClick={() => ShowPageHandler("Like")}
                      style={{
                        color: showPage == "Like" ? "white" : "rgba(152,14,255,255)",
                        background: showPage == "Like"
                          ? "rgba(152,14,255,255)"
                          : "#970eff31",
                      }}
                      className="inline-block py-[7px] px-[10px] rounded-[10px]"
                    >
                      <div className="flex justify-around items-center gap-[10px]">
                        <RiHeart3Fill className="min-w-[25px] min-h-[25px]" />
                        <h6 className="m-0 inline-block text-[20px] font-[400] max-_430_:text-[16px]">
                          {t('Like Me')}
                        </h6>
                      </div>
                    </button>

                    <button
                      onClick={() => ShowPageHandler("Favorite")}
                      style={{
                        color: showPage == "Favorite" ? "white" : "rgba(152,14,255,255)",
                        background: showPage == "Favorite"
                          ? "rgba(152,14,255,255)"
                          : "#970eff31",
                      }}
                      className="inline-block py-[7px] px-[10px] rounded-[10px]"
                    >
                      <div className="flex justify-around items-center gap-[10px]">
                        <RiHeart3Fill className="w-[25px] h-[25px]" />
                        <h6 className="m-0 inline-block text-[20px] font-[400] max-_430_:text-[16px]">
                          {t('Favourite')}
                        </h6>
                      </div>
                    </button>

                    <button
                      onClick={() => ShowPageHandler("Passed")}
                      style={{
                        color: showPage == "Passed" ? "white" : "rgba(152,14,255,255)",
                        background: showPage == "Passed"
                          ? "rgba(152,14,255,255)"
                          : "#970eff31",
                      }}
                      className="inline-block py-[7px] px-[10px] rounded-[10px]"
                    >
                      <div className="flex justify-around items-center gap-[10px]">
                        <RiHeart3Fill className="w-[25px] h-[25px]" />
                        <h6 className="m-0 inline-block text-[20px] font-[400] max-_430_:text-[16px]">
                          {t('Passed')}
                        </h6>
                      </div>
                    </button>

                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-12 max-_430_:py-4">
              {showPage == "Match" && (
                newMatch.length > 0
                  ? <div className="grid grid-cols-4 grid-transition grid-responsive max-_1030_:mt-[130px] max-_768_:mt-[120px] max-_430_:mt-[95px] card p-[16px] card-rounded">
                    {newMatch?.map((el, i) => {
                      return (
                        <div onClick={() => DetailsHandler(el.profile_id , el.profile_name)} className="custom-card cursor-pointer card-rounded-1 duration-[1s] ease-in z-[111]" >
                          <div className="position-relative ">
                            <div className="card-title rounded-1">
                              <div className="card-img relative HEIGHT">
                                <div className="LOADER">
                                  <div className="relative flex items-center justify-center">
                                    <svg className="size-full w-[45px] -rotate-90" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg" >
                                      <circle cx="18" cy="18" r="16" fill="none" className="stroke-current text-[#fffdfd3f]" stroke-width="3" ></circle>
                                      <circle cx="18" cy="18" r="16" fill="none" className="stroke-current text-white dark:text-white" stroke-width="3" stroke-dasharray="100" stroke-dashoffset={100 - el.match_ratio.toFixed(0)} stroke-linecap="round" ></circle>
                                    </svg>
                                    <h6 className="m-0 absolute text-white text-[14px]">{el.match_ratio.toFixed(0)}%</h6>
                                  </div>
                                </div>
                                <div className="KM">
                                  <HiOutlineLocationMarker />
                                  <h6 className="m-0">{el.profile_distance}</h6>
                                </div>
                                <img src={imageBaseURL + el.profile_images[0]} alt="img" className="img-fluid rounded-7 BEFORE border-[3px] border-[rgba(152,14,255,255)]" />
                              </div>
                              <div className="card-content absolute bottom-5 px-[10px]">
                                <div className="flex items-center justify-between">
                                  <h6 className="fw-semi-bold mb-1 text-white">{el.profile_name}, {el.profile_age}</h6>
                                </div>
                                <p className="mb-1 text-[18px] text-start mt-[5px] w-[330px] max-_380_:w-[320px] max-_475_:w-[380px] max-_500_:w-[425px] max-_600_:w-[250px] max-_1090_:w-[300px] max-_1445_:w-[255px] max-_1750_:w-[300px] text-white overflow-ellipsis overflow-hidden whitespace-nowrap">
                                  {el.profile_bio}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>)
                    })}
                  </div>
                  : <div className="p-[16px] bg-white h-[450px] rounded-[0.56rem] flex justify-center items-center max-_1030_:mt-[130px] max-_768_:mt-[120px] max-_430_:mt-[95px]">
                    <h2 className="m-0">{t('No Any Match Profiles...')}</h2>
                  </div>
              )}
              {showPage == "Like" && (
                likeMe.length > 0
                  ? <div className="grid grid-cols-4 grid-transition grid-responsive max-_1030_:mt-[130px] max-_768_:mt-[120px] max-_430_:mt-[95px] card p-[16px] card-rounded">
                    {likeMe.map((el, i) => {
                      return (
                        <div onClick={() => DetailsHandler(el.profile_id)} className="custom-card cursor-pointer card-rounded-1 duration-[1s] ease-in z-[111]" >
                          <div className="position-relative ">
                            <div className="card-title">
                              <div className="card-img relative HEIGHT">
                                <div className="LOADER">
                                  <div className="relative flex items-center justify-center">
                                    <svg className="size-full w-[45px] -rotate-90" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg" >
                                      <circle cx="18" cy="18" r="16" fill="none" className="stroke-current text-[#fffdfd3f]" stroke-width="3" ></circle>
                                      <circle cx="18" cy="18" r="16" fill="none" className="stroke-current text-white dark:text-white" stroke-width="3" stroke-dasharray="100" stroke-dashoffset={100 - el.match_ratio.toFixed(0)} stroke-linecap="round" ></circle>
                                    </svg>
                                    <h6 className="m-0 absolute text-white text-[14px]">{el.match_ratio.toFixed(0)}%</h6>
                                  </div>
                                </div>
                                <div className="KM">
                                  <HiOutlineLocationMarker />
                                  <h6 className="m-0">{el.profile_distance}</h6>
                                </div>
                                <img src={imageBaseURL + el.profile_images[0]} alt="img" className="img-fluid rounded-7 BEFORE border-[3px] border-[rgba(152,14,255,255)]  " />
                              </div>
                              <div className="card-content absolute bottom-5 px-[10px]">
                                <div className="flex items-center justify-between">
                                  <h6 className="fw-semi-bold mb-1 text-white">{el.profile_name}, {el.profile_age}</h6>
                                  <div className="flex gap-[10px]">
                                    <RxCross2 onClick={(e) => { e.stopPropagation(); UserUnLikeHandler(el.profile_id); }} className="w-[40px] h-[40px] p-[5px] rounded-full text-white bg-[rgba(152,14,255,255)]" />
                                    <RiHeart3Fill onClick={(e) => { e.stopPropagation(); UserLikeHandler(el.profile_id); }} className="w-[40px] h-[40px] p-[5px] rounded-full text-white bg-[rgba(152,14,255,255)]" />
                                  </div>
                                </div>
                                <p className="mb-1 text-[18px] text-start mt-[5px] w-[330px] max-_380_:w-[320px] max-_475_:w-[380px] max-_500_:w-[425px] max-_600_:w-[250px] max-_1090_:w-[300px] max-_1445_:w-[255px] max-_1750_:w-[300px] text-white overflow-ellipsis overflow-hidden whitespace-nowrap">
                                  {el.profile_bio}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>)
                    })}
                  </div>
                  : <div className="p-[16px] bg-white rounded-[0.56rem] flex justify-center items-center min-h-[450px]">
                    <h2 className="m-0">{t('No Any User Like You...')}</h2>
                  </div>
              )}
              {showPage == "Favorite" && (
                favourite.length > 0
                  ? <div className="grid grid-cols-4 grid-transition grid-responsive max-_1030_:mt-[130px] max-_768_:mt-[120px] max-_430_:mt-[95px] card p-[16px] card-rounded">
                    {favourite?.map((el, i) => {
                      return (
                        <div onClick={() => DetailsHandler(el.profile_id)} className="custom-card cursor-pointer card-rounded-1 duration-[1s] ease-in z-[111]" >
                          <div className="position-relative ">
                            <div className="card-title">
                              <div className="card-img relative HEIGHT">
                                <div className="LOADER">
                                  <div className="relative flex items-center justify-center">
                                    <svg className="size-full w-[45px] -rotate-90" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg" >
                                      <circle cx="18" cy="18" r="16" fill="none" className="stroke-current text-[#fffdfd3f]" stroke-width="3" ></circle>
                                      <circle cx="18" cy="18" r="16" fill="none" className="stroke-current text-white dark:text-white" stroke-width="3" stroke-dasharray="100" strokeDashoffset={`${100 - el.match_ratio.toFixed(0)}`} stroke-linecap="round" ></circle>
                                    </svg>
                                    <h6 className="m-0 absolute text-white text-[14px]">{el.match_ratio.toFixed(0)}%</h6>
                                  </div>
                                </div>
                                <div className="KM">
                                  <HiOutlineLocationMarker />
                                  <h6 className="m-0">{el.profile_distance}</h6>
                                </div>
                                <img src={imageBaseURL + el.profile_images[0]} alt="img" className="img-fluid rounded-7 BEFORE border-[3px] border-[rgba(152,14,255,255)]" />
                              </div>
                              <div className="card-content absolute bottom-5 px-[10px]">
                                <div className="flex items-center justify-between">
                                  <h6 className="fw-semi-bold mb-1 text-white">{el.profile_name}, {el.profile_age}</h6>
                                </div>
                                <p className="mb-1 text-[18px] mt-[5px] w-[330px] text-start max-_380_:w-[320px] max-_475_:w-[380px] max-_500_:w-[425px] max-_600_:w-[250px] max-_1090_:w-[300px] max-_1445_:w-[255px] max-_1750_:w-[300px] text-white overflow-ellipsis overflow-hidden whitespace-nowrap">
                                  {el.profile_bio}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>)
                    })}
                  </div>
                  : <div className="p-[16px] bg-white rounded-[0.56rem] flex justify-center items-center min-h-[450px]">
                    <h2 className="m-0">{t('No Add Any Favourite Profiles...')}</h2>
                  </div>
              )}
              {showPage == "Passed" && (
                passed.length > 0
                  ? <div className="grid grid-cols-4 grid-transition grid-responsive max-_1030_:mt-[130px] max-_768_:mt-[120px] max-_430_:mt-[95px] card p-[16px] card-rounded">
                    {passed?.map((el, i) => {
                      return (
                        <div onClick={() => DetailsHandler(el.profile_id)} className="custom-card cursor-pointer card-rounded-1 duration-[1s] ease-in z-[111]" >
                          <div className="position-relative ">
                            <div className="card-title">
                              <div className="card-img relative HEIGHT">
                                <div className="LOADER">
                                  <div className="relative flex items-center justify-center">
                                    <svg className="size-full w-[45px] -rotate-90" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg" >
                                      <circle cx="18" cy="18" r="16" fill="none" className="stroke-current text-[#fffdfd3f]" stroke-width="3" ></circle>
                                      <circle cx="18" cy="18" r="16" fill="none" className="stroke-current text-white dark:text-white" stroke-width="3" stroke-dasharray="100" stroke-dashoffset={100 - el.match_ratio.toFixed(0)} stroke-linecap="round" ></circle>
                                    </svg>
                                    <h6 className="m-0 absolute text-white text-[14px]">{el.match_ratio.toFixed(0)}%</h6>
                                  </div>
                                </div>
                                <div className="KM">
                                  <HiOutlineLocationMarker />
                                  <h6 className="m-0">{el.profile_distance}</h6>
                                </div>
                                <img src={imageBaseURL + el.profile_images[0]} alt="img" className="img-fluid rounded-7 BEFORE border-[3px] border-[rgba(152,14,255,255)]" />
                              </div>
                              <div className="card-content absolute bottom-5 px-[10px]">
                                <div className="flex items-center justify-between">
                                  <h6 className="fw-semi-bold mb-1 text-white">{el.profile_name}, {el.profile_age}</h6>
                                </div>
                                <p className="mb-1 text-start text-[18px] mt-[5px] w-[330px] max-_380_:w-[320px] max-_475_:w-[380px] max-_500_:w-[425px] max-_600_:w-[250px] max-_1090_:w-[300px] max-_1445_:w-[255px] max-_1750_:w-[300px] text-white overflow-ellipsis overflow-hidden whitespace-nowrap">
                                  {el.profile_bio}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>)
                    })}
                  </div>
                  : <div className="p-[16px] bg-white rounded-[0.56rem] flex justify-center items-center min-h-[450px]">
                    <h2 className="m-0">{t('No Any Passed Profiles...')}</h2>
                  </div>
              )}
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default Favorites;
