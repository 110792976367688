import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { MyContext } from '../Context/MyProvider ';
import Razorpay from '../PaymentMethod/Razorpay';
import { toast } from 'react-toastify';
import Paypal from '../PaymentMethod/PayPal';
import { StripePayment } from '../PaymentMethod/Stripe';
import PayStack from '../PaymentMethod/PayStack';
import FlutterWave from '../PaymentMethod/FlutterWave';
import SenangPay from '../PaymentMethod/SenangPay';
import Payfast from '../PaymentMethod/Payfast';
import Midtrans from '../PaymentMethod/Midtrans';
import Checkout from '../PaymentMethod/Checkout';
import { useTranslation } from 'react-i18next';
const Upgrade = () => {

  const { t } = useTranslation();

    const { basUrl, imageBaseURL, planId, setPlanId, transactionId, setTransactionId, setPageName, setPayClose, setAmount } = useContext(MyContext);

    const [planLIst, setPlanList] = useState([]);
    const [isVisible, setIsVisible] = useState(false);
    const [payDetails, setPayDetails] = useState([]);
    const [border, setBorder] = useState();
    const [button, setButton] = useState(true);
    const [payId, setPayId] = useState();
    const [id, setId] = useState();
    const [pId, setPId] = useState();
    const [packageId, setPackageId] = useState();
    const [upgrade, setUpgrede] = useState([]);

    const toggleBottomSheet = (e) => {

        if (e == 'PayMent') {
            setIsVisible(false);
        } else {
            setIsVisible(true);
        }
    };

    useEffect(() => {
        const Done = localStorage.getItem("PaymentDone");
        if (Done == "PaymentDone") {
            PurchaseHandler();
            setTransactionId(Date.now());
            setAmount(localStorage.getItem("Amount"));
        }

        const RegisterData = localStorage.getItem("Register_User");
        if (RegisterData) {
            const UserData = JSON.parse(RegisterData);
            if (UserData.plan_id > 0) {
                setPackageId(UserData.plan_id);
            } else {
                setPackageId(localStorage.getItem('PurchaseId'));
            }
        }

        axios.post(`${basUrl}paymentgateway.php`)
            .then((res) => {
                setPayDetails(res.data.paymentdata);
            })
        PalnListHandler();
    }, [])


    const PalnListHandler = () => {
        const localData = localStorage.getItem("Register_User");

        if (localData) {
            const userData = JSON.parse(localData);
            axios.post(`${basUrl}plan.php`, { uid: userData.id })
                .then((res) => {
                    // console.log("Paln List : ", res);
                    setPlanList(res.data.PlanData);
                })
        }
    }

    useEffect(() => {
        if (planId == "PaymentDone") {
            PurchaseHandler();
        } else {
            SectionCloseHandler();
        }
    }, [planId])

    const SendHandler = (Id) => {
        if (border) {
            setPayId(Id);
            setButton(true);

            const Data = {
                Planid: planLIst[id]?.id,
                Pname: Id,
                pMethodid: pId
            }
            localStorage.setItem("Data", JSON.stringify(Data));

        } else {
            toast("💞 Please Select Payment Method", {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
                className: "toast-Style",
            });
        }
    }

    const PurchaseHandler = () => {
        const localData = localStorage.getItem("Register_User");
        const Data = localStorage.getItem("Data")

        if (localData) {
            const userData = JSON.parse(localData);
            if (Data) {
                const Json = JSON.parse(Data);
                axios.post(`${basUrl}plan_purchase.php`,
                    {
                        uid: userData.id,
                        plan_id: Json?.Planid,
                        transaction_id: Date.now(),
                        pname: Json?.Pname,
                        p_method_id: Json?.pMethodid
                    })
                    .then((res) => {
                        console.log(res);
                        if (res.data.Result == "true") {
                            setPackageId(Json?.Planid);
                            localStorage.setItem("PurchaseId", Json?.Planid);
                            toast("💞 Package Purchase Successfully!!", {
                                position: "top-center",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: false,
                                draggable: true,
                                progress: undefined,
                                theme: "dark",
                                className: "toast-Style",
                            });
                            SectionCloseHandler();
                        }
                    })
            } else {
                axios.post(`${basUrl}plan_purchase.php`,
                    {
                        uid: userData.id,
                        plan_id: planLIst[id]?.id,
                        transaction_id: transactionId,
                        pname: payId,
                        p_method_id: pId
                    })
                    .then((res) => {
                        console.log(res);
                        if (res.data.Result == "true") {
                            setPackageId(planLIst[id].id);
                            localStorage.setItem("PurchaseId", planLIst[id].id);
                            toast("💞 Package Purchase Successfully!!", {
                                position: "top-center",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: false,
                                draggable: true,
                                progress: undefined,
                                theme: "dark",
                                className: "toast-Style",
                            });
                            SectionCloseHandler();
                        }
                    })
            }

        }
    }


    const GetStartedHandler = (index, id) => {
        if (packageId < id) {
            toggleBottomSheet("");
            setId(index);
            setPageName("Upgrade");
        } else {
            toast("💞 Allredy Purchase !!", {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
                className: "toast-Style",
            });
        }
    }

    const SectionCloseHandler = () => {
        setPlanId("");
        toggleBottomSheet("PayMent");
        setBorder("");
        setPayId("");
        setButton(false);
        setId("");
        localStorage.setItem("PaymentDone", "");
        localStorage.setItem("Amount", "");
        localStorage.setItem("Data", "");
    }


    return (
        <div>
            <div className="content-body bg-[#e5e5e5] ">
                <div className="container-fluid py-4 px-sm-4 px-3">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card card-rounded mb-4">
                                <div className="card-body card-py-1">
                                    <div className="person-header">
                                        <div className="fw-medium fs-16">{t('Plans')}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 mt-2">
                            <div className="tab-content plan-content">
                                <div className="tab-pane border-0 active show">
                                    <div className="row justify-content-center">
                                        {
                                            planLIst?.map((item, index) => {
                                                return <div key={index} className="col-xxl-3 col-lg-6 col-md-6 col-sm-12 mb-4">
                                                    <div className={`card card-rounded-1 ${item.id == packageId && "ActivePlan"} `}>
                                                        <div className="card-body p-0">
                                                            <div className="p-sm-5 p-4">
                                                                <h3 className="mb-0">{item.title}</h3>
                                                                <h2 className="mb-0">${item.amt}</h2>
                                                                <p className="fw-semi-bold mb-2">Per member, per Month</p>
                                                                <button onClick={() => GetStartedHandler(index, item.id)} style={{ background: "rgba(152,14,255,255)" }} className="btn text-white  my-3">
                                                                    {t('Get Started')}
                                                                </button>
                                                                <div className="plans-includes">
                                                                    <span>Includes:</span>
                                                                </div>
                                                                <ul className="list-unstyled p-0 mt-[15px]">
                                                                    {item.description.split('\n').map((line, index) => (
                                                                        <li className='py-[8px]' key={index}>{line}</li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        {item.id == packageId && <div className="position-absolute popular-title">
                                                            <span style={{ background: "rgba(152,14,255,255)" }} className="card fw-medium">Active </span>
                                                        </div>}
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {isVisible && (
                <div onClick={() => toggleBottomSheet("PayMent")} className="bottom-sheet2 w-full h-full fixed top-0 left-0 flex items-center justify-center bg-black bg-opacity-50 z-[999]">
                    <div onClick={(e) => e.stopPropagation()} className="bottom-sheet-content2">
                        <div className="mt-[10px]">
                            <h6 className='text-[18px]'>Select Payment Methode</h6>
                            <div className="scroll-container2 h-[330px]">
                                {
                                    payDetails.map((item, index) => {
                                        return <div onClick={() => { setBorder(item.title); setPId(item.id) }} className={`${border === item.title ? "border-[rgba(152,14,255,255)]" : "border-gray-300"} mb-[10px] flex items-center cursor-pointer border-[2px] rounded-[10px] px-[10px] py-[12px]`}>
                                            <div className="flex items-center gap-[10px]">
                                                <img src={imageBaseURL + item.img} className='border-[2px] w-[50px] h-[50px] rounded-[10px] bg-gray-200' alt="" />
                                                <div className="mx-[10px]">
                                                    <h4 className='m-0'>{item.title}</h4>
                                                    <p className={`font-[500] text-[14px]`}>{item.subtitle}</p>
                                                </div>
                                            </div>
                                            <div className={`${border === item.title ? "border-[rgba(152,14,255,255)]" : "border-gray-300"} ${item.id == "7" ? "w-[21px]" : item.id == "8" ? "w-[20px]" : "w-[24px]"} h-[20px] border-[2px] rounded-full p-[3px]`}>
                                                <span className={`${border === item.title && "bg-[rgba(152,14,255,255)]"} duration-300 w-[100%] h-[100%] rounded-full block`}></span>
                                            </div>
                                        </div>
                                    })
                                }

                            </div>
                        </div>

                        <button onClick={() => SendHandler(border)} className="text-white bg-[rgba(152,14,255,255)] w-[100%] font-[600] rounded-[10px] py-[10px] mt-[10px]">Continue</button>
                    </div>
                </div>
            )}

            {payId == "Razorpay" && button && <Razorpay Amount={planLIst[id]?.amt} />}
            {payId == "Paypal" && button && <div onClick={() => setPayId("")} id="BlockSection" className=" px-[15px] py-[15px] w-full h-full fixed top-0 left-0 flex items-center justify-center bg-black bg-opacity-50 z-[999]">
                <div onClick={(e) => e.stopPropagation()} className="w-[20%]  max-_430_:w-[100%] max-_768_:w-[90%] max-_1030_:w-[50%] max-_1500_:w-[40%] bg-white rounded-[15px] p-[15px]">
                    <div className="flex flex-col justify-center items-center text-center">
                        <Paypal Amount={planLIst[id]?.amt} />
                    </div>
                </div>
            </div>}
            {payId == "Stripe" && button && <div onClick={() => setPayId("")} id="BlockSection" className=" px-[15px] py-[15px] w-full h-full fixed top-0 left-0 flex items-center justify-center bg-black bg-opacity-50 z-[999]">
                <div onClick={(e) => e.stopPropagation()} className="w-[20%]  max-_430_:w-[100%] max-_768_:w-[90%] max-_1030_:w-[50%] max-_1500_:w-[40%] bg-white rounded-[15px] p-[15px]">
                    <div className="flex flex-col justify-center items-center text-center">
                        <StripePayment Amount={planLIst[id]?.amt} />
                    </div>
                </div>
            </div>}
            {payId == "PayStack" && button && <div onClick={() => setPayId("")} id="BlockSection" className=" px-[15px] py-[15px] w-full h-full fixed top-0 left-0 flex items-center justify-center bg-black bg-opacity-30 z-[999]">
                <div onClick={(e) => e.stopPropagation()} className="w-[20%]  max-_430_:w-[100%] max-_768_:w-[90%] max-_1030_:w-[50%] max-_1500_:w-[40%] bg-white rounded-[15px] p-[15px]">
                    <div className="flex flex-col justify-center items-center text-center">
                        <PayStack Amount={planLIst[id]?.amt} />
                    </div>
                </div>
            </div>}
            {payId == "FlutterWave" && button && <FlutterWave Amount={planLIst[id]?.amt} />}
            {payId == "SenangPay" && button && <SenangPay Amount={planLIst[id]?.amt} />}
            {payId == "Payfast" && button && <Payfast Amount={planLIst[id]?.amt} />}
            {payId == "Midtrans" && button && <Midtrans Amount={planLIst[id]?.amt} />}
            {payId == "2checkout" && button && <Checkout Amount={planLIst[id]?.amt} />}


        </div>
    )
}

export default Upgrade