import React, { useContext, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { MyContext } from "../Context/MyProvider ";
export var golas = "";
const Golas = () => {
  const [Data, setdata] = useState();
  const Dating = useRef();
  const Friendship = useRef();
  const Casul = useRef();
  const Relationship = useRef();
  const Options = useRef();
  const Networking = useRef();
  const Exploration = useRef();
  const navigation = useNavigate();

  const {setGoal} = useContext(MyContext);

  const SelectHandler = (el) => {
    setdata(el)
    if (el === "1") {
      Dating.current.style.borderColor = "#980EFF";
      Friendship.current.style.borderColor = "#D1D5DB";
      Casul.current.style.borderColor = "#D1D5DB";
      Relationship.current.style.borderColor = "#D1D5DB";
      Options.current.style.borderColor = "#D1D5DB";
      Networking.current.style.borderColor = "#D1D5DB";
      Exploration.current.style.borderColor = "#D1D5DB";
    } else if (el === "2") {
      Friendship.current.style.borderColor = "#980EFF";
      Dating.current.style.borderColor = "#D1D5DB";
      Casul.current.style.borderColor = "#D1D5DB";
      Relationship.current.style.borderColor = "#D1D5DB";
      Options.current.style.borderColor = "#D1D5DB";
      Networking.current.style.borderColor = "#D1D5DB";
      Exploration.current.style.borderColor = "#D1D5DB";
    } else if (el === "3") {
      Casul.current.style.borderColor = "#980EFF";
      Friendship.current.style.borderColor = "#D1D5DB";
      Dating.current.style.borderColor = "#D1D5DB";
      Relationship.current.style.borderColor = "#D1D5DB";
      Options.current.style.borderColor = "#D1D5DB";
      Networking.current.style.borderColor = "#D1D5DB";
      Exploration.current.style.borderColor = "#D1D5DB";
    } else if (el === "4") {
      Relationship.current.style.borderColor = "#980EFF";
      Friendship.current.style.borderColor = "#D1D5DB";
      Dating.current.style.borderColor = "#D1D5DB";
      Casul.current.style.borderColor = "#D1D5DB";
      Options.current.style.borderColor = "#D1D5DB";
      Networking.current.style.borderColor = "#D1D5DB";
      Exploration.current.style.borderColor = "#D1D5DB";
    } else if (el === "5") {
      Options.current.style.borderColor = "#980EFF";
      Friendship.current.style.borderColor = "#D1D5DB";
      Dating.current.style.borderColor = "#D1D5DB";
      Casul.current.style.borderColor = "#D1D5DB";
      Relationship.current.style.borderColor = "#D1D5DB";
      Networking.current.style.borderColor = "#D1D5DB";
      Exploration.current.style.borderColor = "#D1D5DB";
    } else if (el === "6") {
      Networking.current.style.borderColor = "#980EFF";
      Friendship.current.style.borderColor = "#D1D5DB";
      Dating.current.style.borderColor = "#D1D5DB";
      Casul.current.style.borderColor = "#D1D5DB";
      Relationship.current.style.borderColor = "#D1D5DB";
      Options.current.style.borderColor = "#D1D5DB";
      Exploration.current.style.borderColor = "#D1D5DB";
    } else if (el === "7") {
      Exploration.current.style.borderColor = "#980EFF";
      Friendship.current.style.borderColor = "#D1D5DB";
      Dating.current.style.borderColor = "#D1D5DB";
      Casul.current.style.borderColor = "#D1D5DB";
      Relationship.current.style.borderColor = "#D1D5DB";
      Options.current.style.borderColor = "#D1D5DB";
      Networking.current.style.borderColor = "#D1D5DB";
    }
  };

  const SubmitHandler = () => {
    if (Data) { 
      navigation("/nearby");
      setGoal(Data);
    } else {
        toast("💞 Please Select Relationship Goals", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "dark",
        className: "toast-Style",
      });
    }
     
  }
  
  return (
    <div>
      <div className="w-[100%] multisteup-wrapper pt-[20px] max-_430_:pt-[0px]">
        <div className="container mx-auto">
          <section class="steps step-1 active  rounded-[40px] relative ">
          <div className="w-[100%] bg-[#EFEDEE]  pt-[30px] z-[999]  pb-[20px] fixed top-[0px] ">
              <div className="bg-white w-[83%] h-[5px] mx-auto rounded-full">
                <div className="bg-[rgba(152,14,255,255)]  rounded-full w-[45%] h-[5px] "></div>
              </div>
            </div>
            {/* <------------------ Title ---------------------> */}
            <div className="text-start w-[100%]">
              <h1 className="text-[28px] max-_430_:text-[26px] font-[600] max-_430_:w-[250px]">
                Your relationship golas 💘
              </h1>
              <p className="text-[20px] mt-[10px] max-_430_:text-[17px] max-_380_:text-[16px]">
                Chooes the type of relationship your're seeking on
                Datify. Lover,friedship, or somethingin between - its's
                your choice.
              </p>
            </div>

            <div className="mt-[20px] w-[100%]">
              <button
                ref={Dating}
                onClick={() => SelectHandler("1")}
                className="text-start mt-[10px] max-_380_:px-[12px] border-[2px] border-gray-300 px-[15px] py-[10px] rounded-[10px]"
              >
                <h1 className="text-[20px] text-black font-[400]">Dating 💑</h1>
                <p className="text-black text-[16px] max-_430_:text-[14px]">
                  Seeking lover and meaningful connections? Choose dating for
                  genuine relationship.
                </p>
              </button>
              <button
                ref={Friendship}
                onClick={() => SelectHandler("2")}
                className="text-start mt-[10px] border-[2px] border-gray-300 px-[15px] py-[10px] rounded-[10px]"
              >
                <h1 className="text-[20px] text-black font-[400]">
                  Friendship 🙌
                </h1>
                <p className="text-black text-[16px] max-_430_:text-[14px]">
                  Expand your social circle and make new friends. Opt for
                  frinedship today.
                </p>
              </button>
              <button
                ref={Casul}
                onClick={() => SelectHandler("3")}
                className="text-start mt-[10px] border-[2px] border-gray-300 px-[15px] py-[10px] rounded-[10px]"
              >
                <h1 className="text-[20px] text-black font-[400]">Casul 😀</h1>
                <p className="text-black text-[16px] max-_430_:text-[14px]">
                  Looking for fun and relaxed encounters? Select casual for
                  carefree connections.
                </p>
              </button>
              <button
                ref={Relationship}
                onClick={() => SelectHandler("4")}
                className="text-start mt-[10px] border-[2px] border-gray-300 px-[15px] py-[10px] rounded-[10px]"
              >
                <h1 className="text-[20px] text-black font-[400]">
                  Serious Relationship 💍
                </h1>
                <p className="text-black text-[16px] max-_430_:text-[14px]">
                  {" "}
                  Ready for commitment and a lasting partnership? Pick serious
                  relationship.
                </p>
              </button>
              <button
                ref={Options}
                onClick={() => SelectHandler("5")}
                className="text-start mt-[10px] border-[2px] border-gray-300 px-[15px] py-[10px] rounded-[10px]"
              >
                <h1 className="text-[20px] text-black font-[400]">
                  Open to Options 😎
                </h1>
                <p className="text-black text-[16px] max-_430_:text-[14px]">
                  Explore various connections and keep your options opne eith
                  this choice.
                </p>
              </button>
              <button
                ref={Networking}
                onClick={() => SelectHandler("6")}
                className="text-start mt-[10px] border-[2px] border-gray-300 px-[15px] py-[10px] rounded-[10px]"
              >
                <h1 className="text-[20px] text-black font-[400]">
                  Networking 🤝
                </h1>
                <p className="text-black text-[16px] max-_430_:text-[14px]">
                  Connect professionally and expand your network. Chose
                  networking now.
                </p>
              </button>
              <button
                ref={Exploration}
                onClick={() => SelectHandler("7")}
                className="text-start mt-[10px] border-[2px] border-gray-300 px-[15px] py-[10px] rounded-[10px]"
              >
                <h1 className="text-[20px] text-black font-[400]">
                  Exploration 🌎
                </h1>
                <p className="text-black text-[16px] max-_430_:text-[14px]">
                  Embark on a journey of discovery. Select exporation for new
                  xeperiences.
                </p>
              </button>
            </div>

            <button style={{background:"rgba(152,14,255,255)"}}
              onClick={SubmitHandler}
              class="btn btn-w-md nextstep mt-[50px]"
            >
              <div className="flex items-center justify-center gap-[10px]">
                <span className="font-bold text-[1.25rem] text-white">Next</span>
                <svg
                  class="mx-6"
                  width="19"
                  height="13"
                  viewBox="0 0 19 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.75455 5.73075H15.4523L11.3296 1.60802C11.2552 1.53617 11.1959 1.45022 11.155 1.35519C11.1142 1.26016 11.0927 1.15795 11.0918 1.05453C11.0909 0.951108 11.1106 0.848543 11.1498 0.752818C11.189 0.657094 11.2468 0.570128 11.3199 0.496995C11.3931 0.423862 11.48 0.366026 11.5758 0.326862C11.6715 0.287698 11.7741 0.267991 11.8775 0.268889C11.9809 0.269788 12.0831 0.291275 12.1781 0.332096C12.2732 0.372918 12.3591 0.432257 12.431 0.50665L17.8833 5.95896C18.0293 6.10503 18.1113 6.30311 18.1113 6.50965C18.1113 6.71618 18.0293 6.91427 17.8833 7.06033L12.431 12.5126C12.2841 12.6545 12.0873 12.733 11.8831 12.7313C11.6789 12.7295 11.4835 12.6476 11.3391 12.5032C11.1947 12.3587 11.1128 12.1634 11.111 11.9592C11.1092 11.7549 11.1877 11.5582 11.3296 11.4113L15.4523 7.28855H1.75455C1.54797 7.28855 1.34986 7.20649 1.20378 7.06041C1.05771 6.91434 0.975649 6.71623 0.975649 6.50965C0.975649 6.30307 1.05771 6.10495 1.20378 5.95888C1.34986 5.81281 1.54797 5.73075 1.75455 5.73075Z"
                    fill="white"
                  />
                </svg>
              </div>
            </button>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Golas;
