import React, { useState } from 'react';

const Midtrans = () => {
  const [loading, setLoading] = useState(false);

  const getTransactionToken = async () => {

    return new Promise((resolve) => {
      setTimeout(() => {
        // Replace this with a real token from the backend.
        resolve('sample_transaction_token');
      }, 1000);
    });
  };


  const handlePayment = async () => {
    setLoading(true);

    const transactionToken = await getTransactionToken();

    if (transactionToken && window.snap) {

      window.snap.pay(transactionToken, {
        onSuccess: function (result) {
          console.log('Payment Success:', result);
          alert('Payment Successful!');
        },
        onPending: function (result) {
          console.log('Payment Pending:', result);
          alert('Payment Pending...');
        },
        onError: function (result) {
          console.log('Payment Error:', result);
          alert('Payment Failed.');
        },
        onClose: function () {
          console.log('Payment Dialog Closed.');
        }
      });
    } else {
      console.log('Snap.js library not loaded or invalid transaction token.');
      alert('Failed to load Snap.js or invalid transaction token.');
    }

    setLoading(false);
  };

  return (
    <div>
      <button onClick={handlePayment} disabled={loading}>
        {loading ? 'Processing...' : 'Pay with Midtrans'}
      </button>
    </div>
  );
};

export default Midtrans;
