import React, { useContext, useEffect, useState } from 'react'
import { MyContext } from '../Context/MyProvider '
import axios from 'axios';
import { useParams } from 'react-router-dom';

const Terms_Conditions = () => {    
    const { basUrl } = useContext(MyContext);
    const Perams = useParams();
    const Title = Perams.title?.replaceAll('_', ' ')
    const [list, setList] = useState([]);

    const ApiHandler = () => {
        axios.post(`${basUrl}pagelist.php`)
            .then((res) => {
                setList(res.data.pagelist);
            })
    }

    useEffect(() => {
        ApiHandler();
    }, [])

    return (
        <div>
            <div className="main-wrapper dashboard ">
                {/* <!-- Main Content Start --> */}
                <div className="content-body bg-[#e5e5e5]">
                    <div className="">
                        {
                            list.map((item, index) => {
                                console.log("Title" , Title);
                                
                                if (item.title.toLowerCase() == Title) {
                                    return <div className="">
                                        <div className="bg-white mx-[20px] p-[16px] my-[30px] rounded-[0.56rem]">
                                            <h3 className='mb-0'>{item.title}</h3>
                                        </div>
                                        <div className='bg-white mx-[20px] p-[16px] my-[30px] rounded-[0.56rem] text-[15px] description' dangerouslySetInnerHTML={{ __html: item?.description }} />
                                    </div>
                                }
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Terms_Conditions