import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { MyContext } from '../Context/MyProvider ';

const Success = () => {
    const { page } = useContext(MyContext);

    const navigat = useNavigate();

    function handlePaymentSuccess() {
        const Pagename = sessionStorage.getItem("Icon-Color");
        
        localStorage.setItem("PaymentDone" , "PaymentDone");

        if (Pagename == "BuyCoin") {
            navigat("/buyCoin");
        } else if (Pagename == "Wallet") {
            navigat("/wallet");
        } else {
            navigat("/upgrade");
        }
    }

    window.onload = function () {
        handlePaymentSuccess();
    };

    return null;
}

export default Success

export const Cancel = () => {
    const navigat = useNavigate();
    function handlePaymentSuccess() {
        localStorage.setItem("PaymentDone" , "PaymentNotDone");
        navigat("/wallet");
    }

    window.onload = function () {
        handlePaymentSuccess();
    };

    return null;

}