import React, { useContext, useEffect, useRef, useState } from 'react'
import ArrowUp from "../Icon/arrow-up.svg"
import Debit from "../Icon/package.svg"
import { MyContext } from '../Context/MyProvider ';
import axios from 'axios';
import Payment from '../PaymentMethod/Payment';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const Wallet = () => {

  const { t } = useTranslation();


    const { basUrl, payClose, setPayClose, toggleButton, setToggleButton, setPageName, amount, setAmount } = useContext(MyContext);

    const [coin, setCoin] = useState();
    const [purchase, setPurchase] = useState([]);

    const CoinHandler = () => {
        const localData = localStorage.getItem("Register_User");

        if (localData) {
            const userData = JSON.parse(localData);

            axios.post(`${basUrl}wallet_report.php`, { uid: userData.id })
                .then((res) => {
                    // console.log("Report : ", res);
                    if (res.data.Result == "true") {
                        setCoin(res.data.wallet);
                        setPurchase(res.data.Walletitem);
                    }
                })
        }
    }

    useEffect(() => {
        CoinHandler();
        const Done = localStorage.getItem("PaymentDone");
        if (Done == "PaymentDone") {
            setPayClose(Done);
            setAmount(localStorage.getItem("Amount"));
        }
        SneckHandler();
    }, [])

    useEffect(() => {
        if (payClose) {
            if (payClose == "PaymentDone") {
                handleWalletUpdate();
            } else {
                SectionCloseHandler();
            }
        }
    }, [payClose])

    const handleWalletUpdate = () => {
        const localData = localStorage.getItem("Register_User");

        if (localData) {
            const userData = JSON.parse(localData);
            axios.post(`${basUrl}wallet_up.php`, {
                uid: userData.id,
                wallet: amount,
            })
                .then((response) => {
                    SectionCloseHandler();
                    console.log("Wallet Update Response: ", response);
                    toast(`💞 ${response.data.ResponseMsg}`, {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                        className: "toast-Style",
                    });
                    CoinHandler();
                })
                .catch((error) => {
                    console.error("Error updating wallet: ", error);
                    toast(`💞 Somthing Want Wrong!! `, {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                        className: "toast-Style",
                    });
                });
        }
    };

    const SectionCloseHandler = () => {
        setToggleButton(false);
        setPayClose("");
        localStorage.removeItem("PaymentDone");
        localStorage.removeItem("Amount");
    }

    const SneckHandler = () => {
        const msg = sessionStorage.getItem("Payment");
        if (msg) {
            toast(`💞 ${msg} `, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
                className: "toast-Style",
            });
            setTimeout(() => {
                sessionStorage.setItem("Payment" , "");
            }, 1000);
        }
    }
    return (
        <div>
            {/* <!-- Main Content Start --> */}
            <div className="content-body bg-[#e5e5e5]">
                <div className="container-fluid pt-5 pb-[20px] px-sm-4 px-3">
                    <div className="row">
                        <div className="col-xl-12 max-_430_:fixed max-_430_:rounded-[10px] max-_430_:bg-[#e5e5e5] top-[70px] left-0 right-0 z-[444] max-_430_:pt-[15px]">
                            <div className="card card-rounded mb-4">
                                <div className="card-body card-py-1 flex justify-between items-center">
                                    <div className="person-header">
                                        <div className="fw-medium fs-15">{t('Total Balance')}</div>
                                        <h2 className='m-0 text-[30px]'>${coin ? coin : "0"}</h2>
                                    </div>
                                    <button onClick={() => { setToggleButton(true); setPageName("Wallet") }} className="flex items-center gap-[10px] bg-[#980EFF] text-white rounded-full px-[8px] py-[5px]">
                                        <img src={ArrowUp} alt="" className='bg-white rounded-full p-[7px] w-[30px] h-[30px]' />
                                        <h6 className='mt-[8px]'>{t("Top-up")}</h6>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 z-[333] ">
                            <div className="card card-rounded-1 max-_430_:mt-[95px]">
                                {purchase.length > 0
                                    ? <div className="card-body">
                                        <h5>{t('Transaction')}</h5>
                                        <div className="mt-[20px]">
                                            {
                                                purchase?.map((item, index) => {
                                                    return <div className="w-[100%] ps-[30px] mb-[20px] pe-[50px] max-_430_:ps-[10px] max-_430_:pe-[10px] py-[10px] justify-between  flex items-center bg-gray-200 rounded-[10px]">
                                                        <div className="flex items-center gap-[30px] max-_430_:gap-[10px]">
                                                            <img src={Debit} className={`${item.status == "Credit" ? "bg-green-300" : "bg-red-300"} w-[40px] h-[40px] rounded-full p-[8px]`} alt="" />
                                                            <div className="">
                                                                <h6 className='m-0 text-[18px] max-_430_:text-[15px]'>{item.message}</h6>
                                                                <h6 className='m-0 text-[14px] text-gray-500 tracking-[1px]'>{item.status}</h6>
                                                            </div>
                                                        </div>
                                                        <h6 className={`${item.status == "Credit" ? "text-green-500" : "text-red-500"}  m-0 text-[18px] max-_430_:text-[16px]`}>{item.status == "Credit" ? "+$" + item.amt : "-$" + item.amt}</h6>
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </div>
                                    : <div className="h-[400px] flex justify-center items-center">
                                        <h3>{t('No any Transaction...')}</h3>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Main Content End --> */}

            {/* ======== Payment Method ============== */}
            {toggleButton && (
                <Payment />
            )}
        </div>

    )
}

export default Wallet